import React, { useState, useEffect } from "react";

// Components
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import BottomMenu from "../../Components/Commons/BottomMenu/BottomMenu";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";
import InputSelectAsync from "../../Components/Commons/InputSelectAsync/InputSelectAsync";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { InputField, TextField, Row, Col } from "@hybris-software/ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Styles
import Style from "./CreateSmartContract.module.css";

const CreateSmartContract = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(null);

  const createSmartContractApi = useQuery({
    url: "contracts/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="Smart Contract Created Successfully"
          buttonOnClick={() => {
            setPopup(null);
            navigate(`/edit-smart-contract?id=${response.data.id}`);
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error.response.data);
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const form = useForm({
    inputs: {
      name: {
        required: true,
      },
      symbol: {
        required: true,
      },
      partner: {
        required: true,
      },
      body: {
        required: true,
      },
    },
  });

  useEffect(() => {
    document.title = "Black Minter | Create Smart Contract";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        {popup && popup}
        <ViewContainer title="Create New Smart Contract">
          <ComponentContainer title="Smart Contract" minHeight="500px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Name</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. Black Minter standard contract"
                  {...form.getInputProps("name")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Symbol</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. Symbol"
                  {...form.getInputProps("symbol")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Partner</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputSelectAsync
                  placeholder="Select partner..."
                  setItem={form.getInputProps("partner").setValue}
                  baseUrl="partners/?minimal=true"
                  error={form.getInputProps("partner").errorDetails}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Smart Contract</h5>
              </Col>
              <Col md={8} sm={12}>
                <TextField
                  rows={15}
                  baseClassName={Style.textArea}
                  successClassName={Style.textArea}
                  errorClassName={Style.textAreaError}
                  placeholder="Copy and paste your smart contract's JSON here"
                  {...form.getInputProps("body")}
                />
              </Col>
            </Row>
          </ComponentContainer>
          <BottomMenu
            createButtonText="CREATE"
            createIsLoading={createSmartContractApi.isLoading}
            createButtonOnClick={() => {
              if (form.validate()) {
                createSmartContractApi.executeQuery(form.getApiBody());
              }
            }}
          ></BottomMenu>
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default CreateSmartContract;
