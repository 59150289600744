import React, { useState, useEffect } from "react";

// Utils
import numberWithCommas from "../../../Utils/numberWithCommas";

// Styles
import Style from "./SlideLoader.module.css";

const SlideLoader = ({ completed, label, partial, total }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(completed + "%");
    //eslint-disable-next-line
  }, []);

  return (
    <div className={Style.mainContainer}>
      <div className={Style.head}>
        <div className={Style.label}>{label}</div>
        <div className={Style.counter}>
          {numberWithCommas(partial) + " / " + numberWithCommas(total)}
        </div>
      </div>
      <div className={Style.body}>
        <div className={Style.bodySlide} style={{ width: width }}></div>
      </div>
    </div>
  );
};

export default SlideLoader;
