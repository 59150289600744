import React, { useState, useEffect } from "react";

// Components
import Loader from "../Loader/Loader";

// Libraries
import useQuery from "@hybris-software/use-query";
import { useNavigate, Link } from "react-router-dom";

// Icons
import { HiMenuAlt1 } from "react-icons/hi";
import { MdNotificationsNone } from "react-icons/md";
import { RiFullscreenExitLine } from "react-icons/ri";
import { BiUser } from "react-icons/bi";
import { VscSignOut } from "react-icons/vsc";
import { HiOutlineChip } from "react-icons/hi";
import { IoMdMailOpen } from "react-icons/io";

// Images
import TokenEth from "./assets/tokenpurple.png";
import BrandLogo from "./assets/logo-extended.svg";
import BrandLogoReduced from "./assets/logo-symbol.svg";

// Audios
import Sound from "./assets/notificationSound.mp3";

// Utils
import truncate from "../../../Utils/truncateText";
import detectIfIsImageFromFileName from "../../../Utils/detectIfIsImageFromFileName";

// Styles
import Style from "./NavBar.module.css";

const NavBar = (props) => {
  const navigate = useNavigate();
  const [logoFull, setLogoFull] = useState("");
  const [logoReduced, setLogoReduced] = useState("");
  const [fullScreenEnabled, setFullScreenEnabled] = useState(true);
  const [notificationsWidget, setNotificationsWidget] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [nftSearchList, setNftSearchList] = useState([]);
  const [smartContractSearchList, setSmartContractSearchList] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [notificationReadUrl, setNotificationReadUrl] = useState(null);
  const [searchUrl, setSearchUrl] = useState(null);

  const readNotificationApi = useQuery({
    url: notificationReadUrl,
    method: "PUT",
    executeImmediately: false,
  });

  const readAllNotificationsApi = useQuery({
    url: "notifications/",
    method: "PUT",
    executeImmediately: false,
  });

  const getNotifications = useQuery({
    url: "notifications/?page_size=50&read=false",
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      setNotificationsCount((oldValue) => {
        if (
          oldValue < response.data.results.length &&
          response.data.results.length > 0
        ) {
          const audio = new Audio(Sound);
          audio.play();
        }
        return response.data.results.length;
      });
      const notificationsWidgetTmp = response.data.results.map(
        (notification, index) => {
          return (
            <div
              id="notificationPopup"
              className={Style.notificationPopUpItem}
              key={index}
            >
              <div
                id="notificationPopup"
                className={Style.deleteNotification}
                onClick={(e) => {
                  setNotificationsCount((oldValue) => {
                    return oldValue - 1;
                  });
                  setNotificationReadUrl(`notifications/${notification.id}/`);
                  const item = e.currentTarget.parentNode;
                  item.style.opacity = "0";
                  item.style.transform = "translateX(-100%)";

                  setTimeout(() => {
                    item.style.visibility = "hidden";
                    item.style.padding = "0";
                    item.style.minHeight = "0";
                    item.style.height = "0";
                  }, 500);
                }}
              >
                <IoMdMailOpen id="notificationPopup" />
              </div>
              <div id="notificationPopup" className={Style.notificationIcon}>
                <HiOutlineChip id="notificationPopup" />
              </div>
              <div id="notificationPopup" className={Style.notificationInfo}>
                <h5 title={notification.message} id="notificationPopup">
                  {notification.message}
                </h5>
                <p id="notificationPopup">{notification.date}</p>
              </div>
            </div>
          );
        }
      );
      setNotificationsWidget(notificationsWidgetTmp);
    },
  });

  const getSearchApi = useQuery({
    url: searchUrl,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      setNftSearchList(response.data.nfts);
      setSmartContractSearchList(response.data.contracts);
    },
  });

  useEffect(() => {
    setLogoFull(BrandLogo);
    setLogoReduced(BrandLogoReduced);
    var userAgent = navigator.userAgent;
    if (userAgent.includes("Chrome/104" || userAgent.includes("Safari"))) {
      setFullScreenEnabled(false);
    }
  }, []);

  useEffect(() => {
    if (notificationReadUrl) {
      readNotificationApi.executeQuery();
    }
    // eslint-disable-next-line
  }, [notificationReadUrl]);

  useEffect(() => {
    if (searchUrl) {
      getSearchApi.executeQuery();
    }
    // eslint-disable-next-line
  }, [searchUrl]);

  useEffect(() => {
    if (props.notificationPopUp === "none") {
      const interval = setInterval(async () => {
        getNotifications.executeQuery();
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [props.notificationPopUp]);

  return (
    <div className={Style.mainContainer}>
      {/* NavBar Desktop */}
      <div className={Style.mainContainerNavDesktop}>
        <div
          className={
            props.compactMenu === "open"
              ? Style.brandLogo
              : props.compactMenu === "compact"
              ? Style.brandLogoCompact
              : Style.brandLogo
          }
          id="brand"
        >
          {/* Brand Logo */}
          <a className={Style.brandLogoLink} href="/">
            <img
              id="brand-img"
              className={Style.brandLogoImg}
              src={
                props.compactMenu === "open"
                  ? logoFull
                  : props.compactMenu === "compact"
                  ? logoReduced
                  : logoFull
              }
              alt="brand-logo"
            />
          </a>
        </div>
        <div
          className={
            props.compactMenu === "open"
              ? Style.navBarContainer
              : props.compactMenu === "compact"
              ? Style.navBarContainerCompact
              : Style.navBarContainer
          }
        >
          <div className={Style.searchBarContainer}>
            {/* Hamburger Menu Button */}
            <div style={{ width: 30 }}>
              <HiMenuAlt1
                className={Style.sideMenuItemIcon}
                onClick={() => {
                  if (props.compactMenu === "open") {
                    props.setCompactMenu("compact");
                  } else {
                    props.setCompactMenu("open");
                  }
                }}
              />
            </div>

            {props.role !== "Viewer" && (
              <div style={{ position: "relative", flexGrow: 2 }}>
                <div
                  className={Style.searchPopup}
                  style={{ display: props.searchPopUp }}
                >
                  {searchLoader === true || getSearchApi.isLoading ? (
                    <Loader height="205px" />
                  ) : nftSearchList.length > 0 ||
                    smartContractSearchList.length > 0 ? (
                    <>
                      <div className={Style.nftSearchPopup}>
                        {nftSearchList.length === 0 ? (
                          <p style={{ fontSize: 14, color: "white" }}>
                            No NFTs Found
                          </p>
                        ) : (
                          nftSearchList.map((nft, index) => {
                            return (
                              <div
                                key={index}
                                className={Style.nftMiniCard}
                                onClick={() => {
                                  navigate(`/edit-nft?id=${nft.id}`);
                                }}
                              >
                                {detectIfIsImageFromFileName(nft.file) ? (
                                  <div
                                    className={Style.nftMiniCardImg}
                                    style={{
                                      backgroundImage: "url(" + nft.file + ")",
                                    }}
                                  ></div>
                                ) : (
                                  <div
                                    className={Style.nftMiniCardImg}
                                    style={{
                                      backgroundColor: "gray",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 12,
                                        textAlign: "center",
                                        fontWeight: 600,
                                        color: "#302f2f",
                                      }}
                                    >
                                      No Preview Available
                                    </p>
                                  </div>
                                )}

                                <div className={Style.nftMiniCardName}>
                                  <h6 title={nft.name}>
                                    {truncate(nft.name, 13)}
                                  </h6>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                      {/* DIVIDER */}
                      <div
                        className={Style.dividerPopUp}
                        style={{ padding: "5px" }}
                      ></div>
                      <div className={Style.smartContractsSearchList}>
                        {smartContractSearchList.length === 0 ? (
                          <p style={{ fontSize: 14, color: "white" }}>
                            No Smart Contracts Found
                          </p>
                        ) : (
                          smartContractSearchList.map(
                            (smartContract, index) => {
                              return (
                                <div
                                  key={index}
                                  className={Style.nftMiniCard}
                                  onClick={() => {
                                    navigate(
                                      `/edit-smart-contract?id=${smartContract.id}`
                                    );
                                  }}
                                >
                                  <div
                                    className={Style.smartContractMiniCardImg}
                                    style={{
                                      backgroundImage: "url(" + TokenEth + ")",
                                    }}
                                  ></div>

                                  <div className={Style.nftMiniCardName}>
                                    <h6 title={smartContract.name}>
                                      {truncate(smartContract.name, 13)}
                                    </h6>
                                  </div>
                                </div>
                              );
                            }
                          )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className={Style.noResults}>
                      <h6>No items found</h6>
                    </div>
                  )}
                </div>

                <input
                  id="searchBarNav"
                  className={Style.searchBar}
                  placeholder="Search for results..."
                  onClick={(e) => {
                    if (e.currentTarget.value.length > 0) {
                      props.setSearchPopUp("block");
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      props.setSearchPopUp("block");
                    } else {
                      setNftSearchList([]);
                      setSmartContractSearchList([]);
                      props.setSearchPopUp("none");
                    }

                    if (!getSearchApi.isLoading) {
                      setSearchLoader(true);
                      setTimeout(async () => {
                        setSearchUrl(`search?search=${e.target.value}`);
                        setSearchLoader(false);
                      }, 1000);
                    }
                  }}
                />
              </div>
            )}
            {/* Search Bar */}
          </div>

          <div className={Style.iconMenuContainer}>
            {fullScreenEnabled === true && (
              <>
                {/* Full Screen Icon */}
                <div className={Style.navBarElement}>
                  <RiFullscreenExitLine
                    className={Style.sideMenuItemIcon}
                    onClick={() => {
                      document.fullscreenEnabled = true;
                      if (!document.fullscreenElement) {
                        document.documentElement.requestFullscreen();
                      } else {
                        document.exitFullscreen();
                      }
                    }}
                  />
                </div>
              </>
            )}

            <div className={Style.navBarElement}>
              {/* PopUp Notifications */}
              <div
                id="notificationPopup"
                className={Style.notificationPopUp}
                style={{ display: props.notificationPopUp }}
                onMouseLeave={() => {
                  props.setNotificationPopUp("none");
                }}
              >
                <div
                  id="notificationPopup"
                  className={Style.notificationPopUpHeading}
                >
                  <div id="notificationPopup">
                    <h5 id="notificationPopup">Notifications</h5>
                  </div>
                  <div
                    id="notificationPopup"
                    className={Style.seeAllNotificationsContainer}
                  >
                    <div
                      id="notificationPopup"
                      className={Style.seeAllNotifications}
                      onClick={() => {
                        setNotificationsWidget([]);
                        setNotificationsCount(0);
                        readAllNotificationsApi.executeQuery();
                      }}
                    >
                      <IoMdMailOpen id="notificationPopup" />
                    </div>
                    <div id="notificationPopup">
                      <h6 id="notificationPopup">Read all</h6>
                    </div>
                  </div>
                </div>

                <div
                  id="notificationPopup"
                  className={Style.dividerPopUpNotification}
                ></div>

                <div
                  id="notificationPopup"
                  className={Style.notificationPopUpBody}
                >
                  {notificationsCount > 0 ? (
                    notificationsWidget
                  ) : (
                    <div className={Style.noNotifications}>
                      No notifications here
                    </div>
                  )}
                </div>
              </div>

              {/* Notifications */}
              <div
                className={Style.notificationIconContainer}
                style={{ position: "relative" }}
                onClick={() => {
                  if (props.notificationPopUp === "none") {
                    props.setNotificationPopUp("block");
                  } else {
                    props.setNotificationPopUp("none");
                  }
                }}
              >
                <div
                  className={
                    notificationsCount > 0 ? Style.pulseElementGreen : null
                  }
                ></div>
                <MdNotificationsNone className={Style.sideMenuItemIcon} />
              </div>
            </div>

            <div className={Style.navBarElement}>
              {/* PopUp Profile */}
              <div
                className={Style.profilePopUp}
                style={{ display: props.profilePopUp }}
                onMouseLeave={() => {
                  props.setProfilePopUp("none");
                }}
              >
                <div className={Style.profilePopUpHeading}>
                  <div style={{ textAlign: "center", padding: "1rem" }}>
                    <h5 style={{ padding: "0.5rem" }}>
                      {props.fullName ? props.fullName : "-"}
                    </h5>
                    <p>{props.role}</p>
                    <p>{props.email}</p>
                  </div>
                </div>

                <div className={Style.dividerPopUp}></div>

                <div className={Style.popUpBody}>
                  <Link to="profile">
                    <div className={Style.profilePopUpLink}>
                      <BiUser className={Style.profilePopUpLinkIcon} />
                      Profile
                    </div>
                  </Link>
                  <div className={Style.dividerPopUp}></div>
                  <div
                    className={Style.profilePopUpLink}
                    onClick={() => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("refreshToken");
                      navigate("/auth/login");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <VscSignOut className={Style.profilePopUpLinkIcon} />
                    Sign Out
                  </div>
                </div>
              </div>

              {/* Avatar */}
              <div
                className={Style.profileAvatar}
                style={{
                  position: "relative",
                }}
                onClick={() => {
                  if (props.profilePopUp === "none") {
                    props.setProfilePopUp("block");
                  } else {
                    props.setProfilePopUp("none");
                  }
                }}
              >
                <span>{props.initials}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
