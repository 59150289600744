function dispatcher(user_info, path, navigate) {

    const permissions = {
        'Editor': ["/", "/nfts", "/create-nft", "/edit-nft"],
        'Viewer': ["/", "/nfts", "/view-nft"]
    }

    if (user_info.role !== "Admin") {
        if (!permissions[user_info.role].includes(path)) {
            navigate("/");
            return false
        }
    }
}

export default dispatcher;