import React from "react";

// Libraries
import { Link } from "react-router-dom";

// Utils
import detectIfIsImageFromFileName from "../../../Utils/detectIfIsImageFromFileName";

// Styles
import Style from "./NftCard.module.css";

const NftCard = ({
  file,
  partner,
  status,
  statusText,
  name,
  onClick,
  role,
}) => {
  return (
    <div className={Style.nftCard}>
      <div className={Style.cardImageContainer}>
        {detectIfIsImageFromFileName(file) === true ? (
          <div
            className={Style.nftCardImage}
            style={{ backgroundImage: `url(${file})` }}
          ></div>
        ) : (
          <div className={Style.nftCardFile}>Preview not available</div>
        )}
      </div>
      <div className={Style.nftCardBody}>
        {partner && (
          <Link to={"/edit-partner?id=" + partner.value}>
            <div className={Style.nftPartner}>{partner.label}</div>
          </Link>
        )}
        <div className={Style.nftName}>{name}</div>
        <span className={Style.status}>
          {status === "DRAFT" && (
            <span className={Style.nftStatusDraft}>Draft</span>
          )}
          {status === "MINTING" && (
            <span className={Style.nftStatusMinting}>
              {statusText + " Minted"}
            </span>
          )}
          {status === "MINTED" && (
            <span className={Style.nftStatusMinted}>
              {statusText + " Minted"}
            </span>
          )}
        </span>
      </div>
      <div
        style={{ height: 1, backgroundColor: "#847f7f47", width: "100%" }}
      ></div>
      <div className={Style.cardButtonContainer}>
        <div
          className={Style.cardButton}
          onClick={() => {
            onClick();
          }}
        >
          {role === "Viewer" ? "DETAIL" : "EDIT"}
        </div>
      </div>
    </div>
  );
};

export default NftCard;
