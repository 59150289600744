import React, { useState, useEffect } from "react";

// Componentns
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import BottomMenu from "../../Components/Commons/BottomMenu/BottomMenu";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { InputField, Row, Col } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Styles
import Style from "./Profile.module.css";

const Profile = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(null);

  const editUserApi = useQuery({
    url: `edit-profile/`,
    method: "PUT",
    executeImmediately: false,
    onSuccess: () => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="User Updated Successfully"
          buttonOnClick={() => {
            setPopup(null);
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error.response.data);
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const getUserDetailsApi = useQuery({
    url: `user-info/`,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      form.getInputProps("full_name").setValue(response.data.full_name || "");
      form.getInputProps("email").setValue(response.data.email || "");
      form.getInputProps("role").setValue(response.data.role || "");
    },
    onError: () => {
      navigate("/");
    },
  });

  const form = useForm({
    inputs: {
      full_name: {
        required: true,
      },
      email: {
        required: true,
        nature: "email",
      },
      role: {
        required: true,
      },
    },
  });

  useEffect(() => {
    document.title = "Black Minter | Profile";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      apiLoading={getUserDetailsApi.isLoading}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={() => {
        return true;
      }}
    >
      <main>
        {popup && popup}
        <ViewContainer title="Profile">
          <ComponentContainer title="Profile" minHeight="300px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Name</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. Gucci"
                  {...form.getInputProps("full_name")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Email</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={true}
                  style={{ backgroundColor: "#3c3c3c" }}
                  placeholder="Ex. mariorossi@gmail.com"
                  {...form.getInputProps("email")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Role</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={true}
                  style={{ backgroundColor: "#3c3c3c" }}
                  placeholder="Ex. mariorossi@gmail.com"
                  {...form.getInputProps("role")}
                />
              </Col>
            </Row>
          </ComponentContainer>
          <BottomMenu
            createButtonText="UPDATE"
            createIsLoading={editUserApi.isLoading}
            createButtonOnClick={() => {
              if (form.validate()) {
                editUserApi.executeQuery(form.getApiBody());
              }
            }}
          ></BottomMenu>
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default Profile;
