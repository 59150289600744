import React, { useState, useEffect } from "react";

// Libraries
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Filler,
  Title,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Styles
import Style from "./LineChart.module.css";

const LineChart = ({ color, labels, data }) => {
  const [gradient, setGradient] = useState(null);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Filler,
    Title
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    barPercentage: 0.1,
    categoryPercentage: 3,
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0.4,
      },
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
        },
      },
      y: {
        grid: {
          drawBorder: false,
          color: "#3232325c",
          width: 0,
        },
      },
    },
  };

  useEffect(() => {
    var ctx = document.getElementById("chart-dashboard").getContext("2d");
    var gradientStroke = ctx.createLinearGradient(0, 0, 0, 300);
    gradientStroke.addColorStop(0, color + "cf");
    gradientStroke.addColorStop(1, color + "1b");
    setGradient(gradientStroke);
    //eslint-disable-next-line
  }, []);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Dataset 1",
        data: data,
        borderColor: color,
        backgroundColor: gradient,
        fill: true,
        borderRadius: 20,
      },
    ],
  };

  return (
    <div className={Style.mainContainer}>
      <Line
        height="400px"
        width="100%"
        id="chart-dashboard"
        data={chartData}
        options={options}
      />
    </div>
  );
};

export default LineChart;
