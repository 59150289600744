import React, { useState, useEffect } from "react";

// Componentns
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import BottomMenu from "../../Components/Commons/BottomMenu/BottomMenu";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";
import InputSwitch from "../../Components/Commons/InputSwitch/InputSwitch";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { InputField, Row, Col } from "@hybris-software/ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Styles
import Style from "./CreateUser.module.css";

const CreateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(null);
  const [rolesList, setRolesList] = useState([true, false, false]);

  const createUserApi = useQuery({
    url: "users/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="User Created Successfully"
          buttonOnClick={() => {
            setPopup(null);
            navigate(`/edit-user?id=${response.data.id}`);
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error.response.data);
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const form = useForm({
    inputs: {
      full_name: {
        required: true,
      },
      email: {
        required: true,
        nature: "email",
      },
      role: {
        required: true,
        value: "Admin",
      },
    },
  });

  useEffect(() => {
    document.title = "Black Minter | Create User";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        {popup && popup}
        <ViewContainer title="Create New User">
          <ComponentContainer title="User" minHeight="500px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Full Name</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. Gucci"
                  {...form.getInputProps("full_name")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Email</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. mariorossi@gmail.com"
                  {...form.getInputProps("email")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Role</h5>
              </Col>
              <Col md={8} sm={12}>
                <h5>Select the role of this user</h5>
                <InputSwitch
                  label="Admin"
                  subLabel="Can invite or remove users, can create NFTs, smart contracts and partners"
                  value={rolesList[0]}
                  onChange={() => {
                    setRolesList([true, false, false]);
                    form.getInputProps("role").setValue("Admin");
                  }}
                />
                <InputSwitch
                  label="Editor"
                  subLabel="Can create NFTs and view statistics"
                  value={rolesList[1]}
                  onChange={() => {
                    setRolesList([false, true, false]);
                    form.getInputProps("role").setValue("Editor");
                  }}
                />
                <InputSwitch
                  label="Viewer"
                  subLabel="Can view statistics and all contents"
                  value={rolesList[2]}
                  onChange={() => {
                    setRolesList([false, false, true]);
                    form.getInputProps("role").setValue("Viewer");
                  }}
                />
              </Col>
            </Row>
          </ComponentContainer>
          <BottomMenu
            createButtonText="CREATE"
            createIsLoading={createUserApi.isLoading}
            createButtonOnClick={() => {
              if (form.validate()) {
                createUserApi.executeQuery(form.getApiBody());
              }
            }}
          ></BottomMenu>
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default CreateUser;
