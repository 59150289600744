import React, { useState, useEffect } from "react";

// Components
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import BottomMenu from "../../Components/Commons/BottomMenu/BottomMenu";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";
import InputSelectAsync from "../../Components/Commons/InputSelectAsync/InputSelectAsync";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { InputField, TextField, Row, Col } from "@hybris-software/ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Styles
import Style from "./EditSmartContract.module.css";

const EditSmartContract = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const smartContractId = params.get("id");

  const editSmartContractApi = useQuery({
    url: `contracts/${smartContractId}/`,
    method: "PUT",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="Smart Contract Updated Successfully"
          buttonOnClick={() => {
            setPopup(null);
            navigate(`/edit-smart-contract?id=${response.data.id}`);
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error.response.data);
      } else if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title="Error!"
            text="Smart Contract Not Found. Smart Contract may have been deleted"
            buttonOnClick={() => {
              navigate("/smart-contracts");
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const getSmartContractDetailsApi = useQuery({
    url: `contracts/${smartContractId}/`,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      form.getInputProps("name").setValue(response.data.name);
      form.getInputProps("symbol").setValue(response.data.symbol);
      form.getInputProps("partner").setValue(response.data.partner?.value);
      form.getInputProps("body").setValue(response.data.body);
    },
    onError: (error) => {
      navigate("/smart-contracts");
    },
  });

  const changeSmartContractStatusApi = useQuery({
    url: `contracts/${smartContractId}/change-status/`,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="Smart Contract Status Changed Successfully"
          buttonOnClick={() => {
            setPopup(null);
            getSmartContractDetailsApi.executeQuery();
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title="Error!"
            text="Smart Contract Not Found. Smart Contract may have been deleted"
            buttonOnClick={() => {
              navigate("/smart-contracts");
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>

                {error.message && error.message} <br />
                {error.response?.data?.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              navigate("/partners");
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const deploySmartContractStatusApi = useQuery({
    url: `contracts/${smartContractId}/deploy/`,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="Smart Contract Status Changed Successfully"
          buttonOnClick={() => {
            setPopup(null);
            getSmartContractDetailsApi.executeQuery();
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title="Error!"
            text="Smart Contract Not Found. Smart Contract may have been deleted"
            buttonOnClick={() => {
              navigate("/smart-contracts");
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response?.data?.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              navigate("/partners");
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const deleteSmartContractApi = useQuery({
    url: `contracts/${smartContractId}/`,
    method: "DELETE",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(null);
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="Smart Contract Deleted Successfully"
          buttonOnClick={() => {
            setPopup(null);
            navigate("/smart-contracts");
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title="Error!"
            text="Smart Contract Not Found. Smart Contract may have been deleted"
            buttonOnClick={() => {
              navigate("/smart-contracts");
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const form = useForm({
    inputs: {
      name: {
        required: true,
      },
      symbol: {
        required: true,
      },
      partner: {
        required: true,
      },
      body: {
        required: true,
      },
    },
  });

  useEffect(() => {
    document.title = "Black Minter | Edit Smart Contract";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={getSmartContractDetailsApi.isLoading}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        {popup && popup}
        <ViewContainer
          title="Edit Smart Contract"
          badge={getSmartContractDetailsApi.response?.data.status}
          badgeStatus={getSmartContractDetailsApi.response?.data.status}
          buttonText={
            getSmartContractDetailsApi.response?.data.status === "DRAFT"
              ? "DEPLOY"
              : getSmartContractDetailsApi.response?.data.status === "DEPLOYED"
              ? null
              : getSmartContractDetailsApi.response?.data.status === "ACTIVE"
              ? "DISABLE"
              : "ACTIVE"
          }
          buttonOnClick={() => {
            const status = getSmartContractDetailsApi.response.data.status;
            if (status === "DRAFT") {
              deploySmartContractStatusApi.executeQuery();
            }
            if (status === "ACTIVE") {
              changeSmartContractStatusApi.executeQuery({
                status: "DISABLED",
              });
            }
            if (status === "DISABLED") {
              changeSmartContractStatusApi.executeQuery({
                status: "ACTIVE",
              });
            }
          }}
          buttonIsLoading={
            changeSmartContractStatusApi.isLoading ||
            deploySmartContractStatusApi.isLoading
          }
        >
          <ComponentContainer title="Smart Contract" minHeight="500px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Name</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={
                    getSmartContractDetailsApi.response?.data.status === "DRAFT"
                      ? false
                      : true
                  }
                  style={{
                    backgroundColor:
                      getSmartContractDetailsApi.response?.data.status ===
                      "DRAFT"
                        ? null
                        : "#3c3c3c",
                  }}
                  placeholder="Ex. Black Minter standard contract"
                  {...form.getInputProps("name")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Symbol</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={
                    getSmartContractDetailsApi.response?.data.status === "DRAFT"
                      ? false
                      : true
                  }
                  style={{
                    backgroundColor:
                      getSmartContractDetailsApi.response?.data.status ===
                      "DRAFT"
                        ? null
                        : "#3c3c3c",
                  }}
                  placeholder="Ex. Symbol"
                  {...form.getInputProps("symbol")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Partner</h5>
              </Col>
              <Col md={8} sm={12}>
                {getSmartContractDetailsApi.response &&
                getSmartContractDetailsApi.response?.data.status === "DRAFT" ? (
                  <InputSelectAsync
                    placeholder="Select partner..."
                    setItem={form.getInputProps("partner").setValue}
                    baseUrl="partners/?minimal=true"
                    error={form.getInputProps("partner").errorDetails}
                    preLoadedItem={
                      getSmartContractDetailsApi.response.data.partner
                    }
                  />
                ) : (
                  <InputField
                    readOnly={
                      getSmartContractDetailsApi.response?.data.status ===
                      "DRAFT"
                        ? false
                        : true
                    }
                    style={{
                      backgroundColor:
                        getSmartContractDetailsApi.response?.data.status ===
                        "DRAFT"
                          ? null
                          : "#3c3c3c",
                    }}
                    placeholder="Ex. Gucci"
                    value={
                      getSmartContractDetailsApi.response?.data.partner?.label
                    }
                  />
                )}
              </Col>
              <Col md={4} sm={12}>
                <h5>Smart Contract</h5>
              </Col>
              <Col md={8} sm={12}>
                <TextField
                  readOnly={
                    getSmartContractDetailsApi.response?.data.status === "DRAFT"
                      ? false
                      : true
                  }
                  style={{
                    backgroundColor:
                      getSmartContractDetailsApi.response?.data.status ===
                      "DRAFT"
                        ? null
                        : "#3c3c3c",
                  }}
                  rows={15}
                  baseClassName={Style.textArea}
                  successClassName={Style.textArea}
                  errorClassName={Style.textAreaError}
                  placeholder="Copy and paste your smart contract's JSON here"
                  {...form.getInputProps("body")}
                />
              </Col>
            </Row>
          </ComponentContainer>
          {getSmartContractDetailsApi.response?.data.status === "DRAFT" && (
            <BottomMenu
              createButtonText="UPDATE"
              createIsLoading={editSmartContractApi.isLoading}
              createButtonOnClick={() => {
                if (form.validate()) {
                  editSmartContractApi.executeQuery(form.getApiBody());
                }
              }}
              deleteButtonText="Delete Smart Contract"
              deleteButtonOnClick={() => {
                setPopup(
                  <GeneralPopup
                    showIcon={true}
                    setPopup={setPopup}
                    title="Are you sure?"
                    text="This action cannot be undone."
                    buttonOnClick={() => {
                      deleteSmartContractApi.executeQuery();
                    }}
                    buttonLoad={true}
                    buttonText="Delete"
                    buttonColor="#008000d1"
                    buttonIsLoading={true}
                    secondaryButtonText="Cancel"
                    secondaryButtonOnClick={() => {
                      setPopup(null);
                    }}
                    secondaryButtonColor="#ff0000d1"
                  />
                );
              }}
            ></BottomMenu>
          )}
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default EditSmartContract;
