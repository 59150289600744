import React from "react";

// Libraries
import { Outlet } from "react-router-dom";

// Images
import background from "./assets/authBackground.jpeg";

// Styles
import Style from "./AuthLayout.module.css";

const AuthLayout = () => (
  <div
    className={Style.mainContainer}
    style={{ backgroundImage: `url(${background})` }}
  >
    <Outlet />
  </div>
);
export default AuthLayout;
