import React from "react";

// Styles
import Style from "./LoadFilePopup.module.css";

const LoadFilePopup = ({ size = 0, uploaded = 0 }) => {
  return (
    <div className={Style.loadingPopup}>
      <h4>File Uploading...</h4>

      <div className={Style.loadingBarContainer}>
        <div className={Style.emptyBar}></div>
        <div
          className={Style.fullBar}
          style={{ width: `${(uploaded / size) * 100}%` }}
        ></div>
      </div>
      <div className={Style.sizeText}>{`${(uploaded / 1024 / 1024).toFixed(
        2
      )} / ${(size / 1024 / 1024).toFixed(2)} MB`}</div>
    </div>
  );
};

export default LoadFilePopup;
