import React, { useEffect, useState } from "react";

// Libraries
import { useNavigate, Link } from "react-router-dom";
import { InputField, Button } from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query/";
import { PermissionRoute } from "@hybris-software/use-auth";
import ViewLoader from "../../../Components/Commons/ViewLoader/ViewLoader";

// Icons
import { MdEmail } from "react-icons/md";

// Images
import Logo from "./assets/logo-extended.svg";

// Styles
import Style from "./ForgotPassword.module.css";

const Login = () => {
  const navigate = useNavigate();
  const [messageSuccess, setMessageSuccess] = useState(false);

  useEffect(() => {
    document.title = "Black Minter | Forgot Password";
  }, []);

  const form = useForm({
    inputs: {
      email: {
        nature: "email",
      },
    },
  });

  const resetPassword = useQuery({
    url: "auth/password/reset/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setMessageSuccess(true);
    },
    onError: (error) => {
      setMessageSuccess(true);
    },
  });

  return (
    <PermissionRoute
      loader={<ViewLoader />}
      minimumLoadingTime={2000}
      forLoggedUser={false}
      unAuthorizedAction={() => {
        navigate("/");
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <div className={Style.mainContainer}>
        {/* Logo */}
        <div className={Style.logoContainer}>
          <img src={Logo} alt="logo" width={150}></img>
        </div>

        {/* Forgot Password Form */}
        <div className={Style.loginContainer}>
          <div className={Style.loginHeader}>
            <h3>Forgot Password</h3>

            {!messageSuccess ? (
              <p>Enter the email you used to register your account</p>
            ) : (
              <p>Email sent correctly, please check your inbox.</p>
            )}
          </div>
          {!messageSuccess && (
            <>
              <InputField
                baseClassName={Style.baseClassInput}
                errorMessageClassName={Style.errorMessageClassName}
                errorClassName={Style.errorClassName}
                successClassName={Style.successClassName}
                errorIconVisibility={false}
                icon={
                  <div className={Style.inputIcon}>
                    <MdEmail />
                  </div>
                }
                placeholder="Email"
                {...form.getInputProps("email")}
              />

              <Link to="/auth/login">
                <p className={Style.goBack}>Take me back</p>
              </Link>

              <Button
                style={{ display: "flex" }}
                disabled={resetPassword.isLoading || !form.isValid()}
                onClick={() => {
                  resetPassword.executeQuery(form.getApiBody());
                }}
                isLoading={resetPassword.isLoading}
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </div>
    </PermissionRoute>
  );
};

export default Login;
