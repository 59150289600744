function detectIfIsImageFromFileName(file_name) {
  const string = file_name.toLowerCase();

  const image_extensions = [
    "apng",
    "avif",
    "gif",
    "jpg",
    "jpeg",
    "jfif",
    "jp2",
    "jpx",
    "pjpeg",
    "pjp",
    "png",
    "svg",
    "tif",
    "tiff",
    "webp",
    "webm",
    "bmp",
    "ico",
    "heic",
    "heif",
    "cur",
  ];

  const extension = string.split(".").pop();

  if (image_extensions.includes(extension)) {
    return true;
  } else {
    return false;
  }
}

export default detectIfIsImageFromFileName;