import React from "react";
import Style from "./SideBar.module.css";

import { FiHome } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { HiOutlineChip } from "react-icons/hi";
import { FiLayers } from "react-icons/fi";
import { FiSettings } from "react-icons/fi";
import { HiUsers } from "react-icons/hi";

import { Link, useLocation } from "react-router-dom";

const SideBar = (props) => {
  const location = useLocation();

  function dropdown(id, icon_id) {
    const dropdown = document.getElementById(id);
    const arrow = document.getElementById(icon_id);
    dropdown.classList.toggle(Style.active);
    arrow.classList.toggle(Style.active_arrow);
  }

  const sideConstructor = [
    {
      sectionTitle: "Main",
      permissions: ["Admin", "Editor", "Viewer"],
      mainItems: [
        {
          title: "Dashboard",
          icon: <FiHome className={Style.sideMenuItemIcon} />,
          link: "",
          permissions: ["Admin", "Editor", "Viewer"],
        },
      ],
    },
    {
      sectionTitle: "Nft",
      permissions: ["Admin", "Editor", "Viewer"],
      mainItems: [
        {
          title: "NFT",
          id: "settings",
          iconId: "nft_menu_icon",
          icon: <HiOutlineChip className={Style.sideMenuItemIcon} />,
          permissions: ["Admin", "Editor", "Viewer"],
          subItems: [
            {
              title: "Create NFT",
              link: "create-nft",
              permissions: ["Admin", "Editor"],
              activeFor: ["/create-nft"],
            },
            {
              title: "NFTs",
              link: "nfts",
              permissions: ["Admin", "Editor", "Viewer"],
              activeFor: ["/nfts"],
            },
            {
              title: "Smart Contracts",
              link: "smart-contracts",
              permissions: ["Admin"],
              activeFor: ["/smart-contracts"],
            },
            {
              title: "Edit Smart Contract",
              link: "edit-smart-contract",
              permissions: ["Admin"],
              activeFor: ["/edit-smart-contract"],
              hideInSidebar: true,
            },
            {
              title: "Edit NFT",
              link: "edit-nft",
              permissions: ["Admin"],
              activeFor: ["/edit-nft"],
              hideInSidebar: true,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: "Settings",
      permissions: ["Admin"],
      mainItems: [
        {
          title: "Partners",
          id: "partners_menu",
          iconId: "partners_menu_icon",
          icon: <FiLayers className={Style.sideMenuItemIcon} />,
          permissions: ["Admin"],
          subItems: [
            {
              title: "Create Partner",
              link: "create-partner",
              permissions: ["Admin"],
              activeFor: ["/create-partner"],
            },
            {
              title: "Partners",
              link: "partners",
              permissions: ["Admin"],
              activeFor: ["/partners"],
            },
            {
              title: "Edit Partner",
              link: "edit-partner",
              permissions: ["Admin"],
              activeFor: ["/edit-partner"],
              hideInSidebar: true,
            },
          ],
        },
        {
          title: "General",
          id: "general_menu",
          iconId: "general_menu_icon",
          icon: <FiSettings className={Style.sideMenuItemIcon} />,
          permissions: ["Admin"],
          subItems: [
            {
              title: "Wallet",
              link: "platform-wallet",
              permissions: ["Admin"],
              activeFor: ["/platform-wallet"],
            },
            {
              title: "Config",
              link: "config",
              permissions: ["Admin"],
              activeFor: ["/config"],
            },
          ],
        },
        {
          title: "Users",
          id: "users_menu",
          iconId: "users_menu_icon",
          icon: <HiUsers className={Style.sideMenuItemIcon} />,
          permissions: ["Admin"],
          subItems: [
            {
              title: "Create User",
              link: "create-user",
              permissions: ["Admin"],
              activeFor: ["/create-user"],
            },
            {
              title: "Users",
              link: "users",
              permissions: ["Admin"],
              activeFor: ["/users"],
            },
            {
              title: "Edit User",
              link: "edit-user",
              permissions: ["Admin"],
              activeFor: ["/edit-user"],
              hideInSidebar: true,
            },
          ],
        },
      ],
    },
  ];

  return (
    <div
      className={
        props.compactMenu === "open"
          ? Style.menuContainer
          : props.compactMenu === "compact"
          ? Style.menuContainerCompact
          : Style.menuContainer
      }
      onMouseOver={() => {
        const width = document.documentElement.clientWidth;
        if (width > 991) {
          if (props.compactMenu === "compact") {
            props.setCompactMenu("over");
          }
        }
      }}
      onMouseLeave={() => {
        if (props.compactMenu === "over") {
          props.setCompactMenu("compact");
        }
      }}
    >
      <ul
        style={{
          padding: 0,
          maxHeight: "100%",
          paddingBottom: "100px",
          overflowY: "scroll",
        }}
      >
        {sideConstructor.map((item, index) => {
          if (item.permissions.includes(props.role)) {
            return (
              <React.Fragment key={index}>
                <li
                  className={
                    props.compactMenu === "open"
                      ? Style.subCategory
                      : props.compactMenu === "compact"
                      ? Style.subCategoryCompact
                      : Style.subCategory
                  }
                >
                  <h3>{item.sectionTitle}</h3>
                </li>

                {item.mainItems.map((mainItem, index) => {
                  if (mainItem.permissions.includes(props.role)) {
                    if (mainItem.subItems) {
                      const subLinks = mainItem.subItems.map(
                        (subItem, index) => subItem.link
                      );
                      return (
                        <React.Fragment key={index}>
                          <ul style={{ padding: 0, marginBottom: "15px" }}>
                            <li
                              className={
                                subLinks.includes(
                                  location.pathname.replace("/", "")
                                )
                                  ? props.compactMenu === "open"
                                    ? Style.slideDropdownSelected
                                    : props.compactMenu === "compact"
                                    ? Style.slideDropdownCompactSelected
                                    : Style.slideDropdownSelected
                                  : props.compactMenu === "open"
                                  ? Style.slideDropdown
                                  : props.compactMenu === "compact"
                                  ? Style.slideDropdownCompact
                                  : Style.slideDropdown
                              }
                              onClick={() => {
                                dropdown(mainItem.id, mainItem.iconId);
                              }}
                            >
                              <span
                                className={
                                  props.compactMenu === "open"
                                    ? Style.sideMenuItemDropdown
                                    : props.compactMenu === "compact"
                                    ? Style.sideMenuItemDropdownCompact
                                    : Style.sideMenuItemDropdown
                                }
                              >
                                <span style={{ display: "flex", columnGap: 8 }}>
                                  {mainItem.icon}
                                  <div
                                    className={
                                      props.compactMenu === "open"
                                        ? Style.sideMenuLael
                                        : props.compactMenu === "compact"
                                        ? Style.sideMenuLabelCompact
                                        : Style.sideMenuLabel
                                    }
                                  >
                                    <span>{mainItem.title}</span>
                                  </div>
                                </span>
                                <IoIosArrowDown
                                  id={mainItem.iconId}
                                  className={
                                    props.compactMenu === "open"
                                      ? Style.mainMenuArrow
                                      : props.compactMenu === "compact"
                                      ? Style.mainMenuArrowCompact
                                      : Style.mainMenuArrow
                                  }
                                />
                              </span>
                            </li>

                            <ul id={mainItem.id} className={Style.side_ul}>
                              {mainItem.subItems.map((subItem, index) => {
                                if (
                                  subItem.permissions.includes(props.role) &&
                                  subItem.hideInSidebar !== true
                                ) {
                                  return (
                                    <li
                                      key={index}
                                      style={{ alignItems: "center" }}
                                      className={
                                        props.compactMenu === "open"
                                          ? Style.subMenuContainer
                                          : props.compactMenu === "compact"
                                          ? Style.subMenuContainerCompact
                                          : Style.subMenuContainer
                                      }
                                    >
                                      <span className={Style.subMenuContainer}>
                                        <IoIosArrowDown
                                          className={Style.subMenuArrow}
                                        />
                                        <Link
                                          to={subItem.link}
                                          className={Style.subMenuItem}
                                          style={{
                                            color:
                                              subItem.activeFor.includes(
                                                location.pathname
                                              ) && "#fff",
                                            fontWeight:
                                              subItem.activeFor.includes(
                                                location.pathname
                                              ) && 400,
                                          }}
                                        >
                                          {subItem.title}
                                        </Link>
                                      </span>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <React.Fragment
                                      key={index}
                                    ></React.Fragment>
                                  );
                                }
                              })}
                            </ul>
                          </ul>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={index}>
                          <li
                            className={
                              mainItem.link ===
                              location.pathname.replace("/", "")
                                ? props.compactMenu === "open"
                                  ? Style.slideDropdownSelected
                                  : props.compactMenu === "compact"
                                  ? Style.slideDropdownCompactSelected
                                  : Style.slideDropdownSelected
                                : props.compactMenu === "open"
                                ? Style.slideDropdown
                                : props.compactMenu === "compact"
                                ? Style.slideDropdownCompact
                                : Style.slideDropdown
                            }
                          >
                            <Link
                              className={Style.sideMenuItem}
                              to={mainItem.link}
                            >
                              <FiHome className={Style.sideMenuItemIcon} />
                              <div
                                className={
                                  props.compactMenu === "open"
                                    ? Style.sideMenuLabel
                                    : props.compactMenu === "compact"
                                    ? Style.sideMenuLabelCompact
                                    : Style.sideMenuLabel
                                }
                              >
                                {mainItem.title}
                              </div>
                            </Link>
                          </li>
                        </React.Fragment>
                      );
                    }
                  } else {
                    return <></>;
                  }
                })}
              </React.Fragment>
            );
          } else {
            return <React.Fragment key={index}></React.Fragment>;
          }
        })}
      </ul>
    </div>
  );
};

export default SideBar;
