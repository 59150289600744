import React, { useState, useEffect, useMemo } from "react";

// Components
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import GeneralTable from "../../Components/Commons/GeneralTable/GeneralTable";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";

// Libraries
import { useNavigate, useLocation } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import { PermissionRoute } from "@hybris-software/use-auth";

// Icons
import { AiOutlinePlus } from "react-icons/ai";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Styles
import tableStyle from "../../Assets/css/Table.module.css";

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [url, setUrl] = useState(null);
  const [status, setStatus] = useState("all");
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState(null);
  const [sortedBy, setSortedBy] = useState("name");
  const [sortedType, setSortedType] = useState("asc");

  // Table Columns
  const columns = useMemo(
    () => [
      {
        Header: "FULL NAME #",
        field: "full_name",
        accessor: (row) => {
          return (
            <div
              style={{
                width: "100%",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => {
                navigate(`/edit-user?id=${row.id}`);
              }}
            >
              {row.full_name ? row.full_name : "Not available"}
            </div>
          );
        },
      },
      {
        Header: "EMAIL",
        field: "email",
        accessor: "email",
      },
      {
        Header: "ROLE",
        field: "role",
        accessor: "role",
      },
      {
        Header: "STATUS",
        field: "status",
        accessor: (row) => {
          if (row.status === "ACTIVE") {
            return (
              <div className={tableStyle.badgeContainer}>
                <div className={tableStyle.active}>Active</div>
              </div>
            );
          } else if (row.status === "DISABLED") {
            return (
              <div className={tableStyle.badgeContainer}>
                <div className={tableStyle.disabled}>Disabled</div>
              </div>
            );
          }
        },
      },
    ],
    //eslint-disable-next-line
    []
  );

  const tableApi = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      console.clear();
      setTableData(response.data);
    },
  });

  useEffect(() => {
    setUrl(
      `users/?status=${status}&search=${search}&field=${sortedBy}&sort=${sortedType}`
    );
  }, [status, search, sortedBy, sortedType]);

  useEffect(() => {
    if (url) {
      tableApi.executeQuery();
    }
    //eslint-disable-next-line
  }, [url]);

  useEffect(() => {
    document.title = "Black Minter | Users";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        <ViewContainer
          title="Users"
          buttonText={
            <>
              <AiOutlinePlus style={{ marginRight: 5 }} /> NEW USER
            </>
          }
          buttonOnClick={() => {
            navigate("/create-user");
          }}
        >
          <ComponentContainer
            title="Users"
            filters={[
              {
                name: "All",
                onClick: () => {
                  setStatus("ALL");
                },
                default: true,
              },
              {
                name: "Active",
                onClick: () => {
                  setStatus("ACTIVE");
                },
              },
              {
                name: "Disabled",
                onClick: () => {
                  setStatus("DISABLED");
                },
              },
            ]}
          >
            {tableData && (
              <GeneralTable
                data={tableData}
                columns={columns}
                setUrl={setUrl}
                searchPlaceholder="Search..."
                setSearch={setSearch}
                isLoading={tableApi.isLoading}
                sortedBy={sortedBy}
                setSortedBy={setSortedBy}
                sortedType={sortedType}
                setSortedType={setSortedType}
              />
            )}
          </ComponentContainer>
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default Users;
