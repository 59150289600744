import React from "react";

// Icons
import { BsExclamationTriangle } from "react-icons/bs";

// Styles
import Style from "./NoDataLoaded.module.css";

const NoDataLoaded = ({height, errorMessage="Data not loaded correctly"}) => {
  return (
    <div
      className={Style.mainContainer}
      style={{
        height: height ? height : null,
      }}
    >
      <BsExclamationTriangle />
      <h5>
        {errorMessage}
      </h5>
    </div>
  );
};

export default NoDataLoaded;
