import React from "react";

// Components
import NoDataLoaded from "../NoDataLoaded/NoDataLoaded";

// Libraries
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  PointElement,
  LinearScale,
  LineElement,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";

// Utils
import numberWithCommas from "../../../Utils/numberWithCommas";

// Styles
import Style from "./StatisticsBox.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title
);

const options = {
  maintainAspectRatio: false,
  barPercentage: 0.1,
  categoryPercentage: 3,
  elements: {
    point: {
      radius: 0,
    },
    line: {
      tension: 0.4,
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      ticks: {
        display: false,
        beginAtZero: true,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
};

const StatisticsBox = ({
  labels,
  title,
  data,
  color,
  noDataLoaded,
  amount,
  preCurrency,
  postCurrency,
  increase,
  type,
  percentage,
  timeLabel,
}) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Dataset 1",
        data: data,
        borderColor: color,
        backgroundColor: color,
        borderRadius: 20,
      },
    ],
  };

  return (
    <div className={Style.mainContainer}>
      {noDataLoaded === true ? (
        <NoDataLoaded />
      ) : (
        <>
          <div className={Style.infoContainer}>
            <div className={Style.boxTitle}>{title}</div>
            <div className={Style.boxNum}>
              {typeof amount === "object" ? (
                <>
                  <span className={Style.amountMinted}>
                    {numberWithCommas(amount.minted)}
                  </span>
                  <span className={Style.amountTotal}>
                    {"/" + numberWithCommas(amount.total)}
                  </span>
                  <span className={Style.chartCurrency}>{postCurrency}</span>
                </>
              ) : (
                <>
                  <span className={Style.chartCurrency}>{preCurrency}</span>
                  {numberWithCommas(amount)}
                  <span className={Style.chartCurrency}>
                    &nbsp;{postCurrency}
                  </span>
                </>
              )}
            </div>
            <div className={Style.boxPercentage}>
              <span
                className={Style.boxPercentageIcon}
                style={{ color: color }}
              >
                {increase === true ? (
                  <BsArrowUpCircle />
                ) : (
                  <BsArrowDownCircle />
                )}
                &nbsp;{percentage}%
                <span className={Style.boxPercentageTime}>
                  &nbsp;{timeLabel}
                </span>
              </span>
            </div>
          </div>
          <div className={Style.chartContainer}>
            {type === "bar" && (
              <Bar
                data={chartData}
                options={options}
                width="50%"
                height="40px"
              />
            )}
            {type === "line" && (
              <Line
                data={chartData}
                options={options}
                width="50%"
                height="40px"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StatisticsBox;
