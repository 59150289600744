import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";

// Styles
import Style from "./BottomMenu.module.css";

const BottomMenu = ({
  deleteButtonText,
  deleteButtonOnClick,
  deleteIsDisabled = false,
  deleteIsLoading,
  cancelButtonText,
  cancelButtonOnClick,
  cancelIsDisabled = false,
  cancelIsLoading,
  createButtonText,
  createButtonOnClick,
  createIsDisabled = false,
  createIsLoading,
}) => {
  return (
    <>
      <div
        style={{
          marginTop: 40,
          height: 1,
          width: "100%",
          backgroundColor: "#ffffff29",
        }}
      />
      <div className={Style.bottomMenuContainer}>
        {deleteButtonText ? (
          <Button
            buttonClassName={Style.buttonClassName}
            buttonDisabledClassName={Style.buttonDisabledClassName}
            onClick={() => {
              deleteButtonOnClick();
            }}
            disabled={deleteIsDisabled}
            isLoading={deleteIsLoading}
          >
            {deleteButtonText}
          </Button>
        ) : (
          <span></span>
        )}
        <div>
          {cancelButtonText ? (
            <Button
              onClick={() => {
                cancelButtonOnClick();
              }}
              disabled={cancelIsDisabled}
              isLoading={cancelIsLoading}
            >
              {cancelButtonText}
            </Button>
          ) : (
            <span></span>
          )}

          {createButtonText ? (
            <Button
              onClick={() => {
                createButtonOnClick();
              }}
              disabled={createIsDisabled}
              isLoading={createIsLoading}
            >
              {createButtonText}
            </Button>
          ) : (
            <span></span>
          )}
        </div>
      </div>
    </>
  );
};

export default BottomMenu;
