import React from "react";

// Styles
import Style from "./LayoutLoader.module.css";

const LayoutLoader = () => {
  return (
    <div className={Style.loaderContainer}>
      <div className={Style.viewLoader}></div>
    </div>
  );
};

export default LayoutLoader;
