import React, { useEffect } from "react";

// Components
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";

// Libraries
import { useNavigate } from "react-router-dom";
import { PermissionRoute } from "@hybris-software/use-auth";

const Config = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Black Minter | Config";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <ViewContainer title="Config">
        <ComponentContainer
          title="Config"
          minHeight="200px"
        ></ComponentContainer>
      </ViewContainer>
    </PermissionRoute>
  );
};

export default Config;
