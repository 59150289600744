import React, { useState, useEffect } from "react";

// Componentns
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import BottomMenu from "../../Components/Commons/BottomMenu/BottomMenu";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";
import InputSwitch from "../../Components/Commons/InputSwitch/InputSwitch";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { InputField, Row, Col } from "@hybris-software/ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Styles
import Style from "./EditUser.module.css";

const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(null);
  const [rolesList, setRolesList] = useState([true, false, false]);
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("id");

  const editUserApi = useQuery({
    url: `users/${userId}/`,
    method: "PUT",
    executeImmediately: false,
    onSuccess: () => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="User Updated Successfully"
          buttonOnClick={() => {
            setPopup(null);
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error.response.data);
      } else if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title="Error!"
            text="User Not Found. User may have been deleted"
            buttonOnClick={() => {
              navigate("/users");
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response?.data?.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const getUserDetailsApi = useQuery({
    url: `users/${userId}/`,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      form.getInputProps("full_name").setValue(response.data.full_name || "");
      form.getInputProps("email").setValue(response.data.email || "");
      form.getInputProps("role").setValue(response.data.role || "");

      if (response.data.role === "Admin") {
        setRolesList([true, false, false]);
      } else if (response.data.role === "Editor") {
        setRolesList([false, true, false]);
      } else if (response.data.role === "Viewer") {
        setRolesList([false, false, true]);
      }
    },
    onError: () => {
      navigate("/users");
    },
  });

  const changeUserStatusApi = useQuery({
    url: `users/${userId}/change-status/`,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="User Status Changed Successfully"
          buttonOnClick={() => {
            setPopup(null);
            getUserDetailsApi.executeQuery();
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={false}
            setPopup={setPopup}
            title="Error!"
            text="User Not Found. User may have been deleted"
            buttonOnClick={() => {
              setPopup(null);
              navigate("/users");
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const deleteUserApi = useQuery({
    url: `users/${userId}/`,
    method: "DELETE",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(null);
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="User Deleted Successfully"
          buttonOnClick={() => {
            setPopup(null);
            navigate("/users");
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title="Error!"
            text="User Not Found. User may have been deleted"
            buttonOnClick={() => {
              setPopup(null);
              navigate("/users");
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const form = useForm({
    inputs: {
      full_name: {
        required: true,
      },
      email: {
        required: true,
        nature: "email",
      },
      role: {
        required: true,
        value: "Admin",
      },
    },
  });

  useEffect(() => {
    document.title = "Black Minter | Edit User";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      apiLoading={getUserDetailsApi.isLoading}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        {popup && popup}
        <ViewContainer
          title="Edit User"
          badge={getUserDetailsApi.response?.data.status}
          badgeStatus={getUserDetailsApi.response?.data.status}
          buttonText={
            getUserDetailsApi.response?.data.status === "ACTIVE"
              ? "DISABLE"
              : "ACTIVATE"
          }
          buttonOnClick={() => {
            if (getUserDetailsApi.response?.data.status === "ACTIVE") {
              changeUserStatusApi.executeQuery({
                status: "DISABLED",
              });
            } else {
              changeUserStatusApi.executeQuery({
                status: "ACTIVE",
              });
            }
          }}
          buttonIsLoading={changeUserStatusApi.isLoading}
        >
          <ComponentContainer title="User" minHeight="500px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Name</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. Gucci"
                  {...form.getInputProps("full_name")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Email</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. mariorossi@gmail.com"
                  {...form.getInputProps("email")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Role</h5>
              </Col>
              <Col md={8} sm={12}>
                <h5>Select the role of this user</h5>
                <InputSwitch
                  label="Admin"
                  subLabel="Can invite or remove users, can create NFTs, smart contracts and partners"
                  value={rolesList[0]}
                  onChange={() => {
                    setRolesList([true, false, false]);
                    form.getInputProps("role").setValue("Admin");
                  }}
                />
                <InputSwitch
                  label="Editor"
                  subLabel="Can create NFTs and view statistics"
                  value={rolesList[1]}
                  onChange={() => {
                    setRolesList([false, true, false]);
                    form.getInputProps("role").setValue("Editor");
                  }}
                />
                <InputSwitch
                  label="Viewer"
                  subLabel="Can view statistics and all contents"
                  value={rolesList[2]}
                  onChange={() => {
                    setRolesList([false, false, true]);
                    form.getInputProps("role").setValue("Viewer");
                  }}
                />
              </Col>
            </Row>
          </ComponentContainer>
          <BottomMenu
            createButtonText="UPDATE"
            createIsLoading={editUserApi.isLoading}
            createButtonOnClick={() => {
              if (form.validate()) {
                editUserApi.executeQuery(form.getApiBody());
              }
            }}
            deleteButtonText="Delete User"
            deleteButtonOnClick={() => {
              setPopup(
                <GeneralPopup
                  showIcon={true}
                  setPopup={setPopup}
                  title="Are you sure?"
                  text="This action cannot be undone."
                  buttonOnClick={() => {
                    deleteUserApi.executeQuery();
                  }}
                  buttonLoad={true}
                  buttonText="Delete"
                  buttonColor="#008000d1"
                  buttonIsLoading={true}
                  secondaryButtonText="Cancel"
                  secondaryButtonOnClick={() => {
                    setPopup(null);
                  }}
                  secondaryButtonColor="#ff0000d1"
                />
              );
            }}
          ></BottomMenu>
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default EditUser;
