import React, { useState, useEffect } from "react";

// Libraries
import { TailSpin } from "react-loading-icons";
import useQuery from "@hybris-software/use-query";

// Icons
import { GiFlatPlatform } from "react-icons/gi";

// Styles
import Style from "./InputSelectAsync.module.css";

const InputSelectAsync = ({
  setItem,
  onChange,
  baseUrl,
  placeholder,
  error,
  preLoadedItem,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [checkDistance, setCheckDistance] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);
  const [url, setUrl] = useState(baseUrl);
  const [nextUrl, setNextUrl] = useState(null);

  const getPartnersListApi = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      setItems((oldItems) => [...oldItems, ...response.data.results]);
      if (response.data.links.next) {
        setNextUrl(response.data.links.next);
      } else {
        setNextUrl(null);
      }
    },
  });

  function calculateDistanceBetweenElementAndPageBottom(el) {
    const rect = el.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    const distance = -(rect.top + rect.height - scrollTop - clientHeight);
    return distance;
  }

  function handleSetItem(item) {
    setSelectedItem(item);
    setItem(item.value);
  }

  function createOptions(options) {
    return options.map((item, index) => (
      <div
        key={index}
        className={Style.customSelectOption}
        value={item.value}
        onClick={() => {
          handleSetItem(item);
          setShowDropdown(false);
          onChange !== undefined && onChange(item);
        }}
      >
        {item.is_active !== undefined && (
          <span
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: item.is_active === true ? "green" : "red",
              borderRadius: "50%",
            }}
          ></span>
        )}
        &nbsp;
        {item.label}
        &nbsp;
        {item.is_platform && <GiFlatPlatform />}
      </div>
    ));
  }

  useEffect(() => {
    setSelectedItem(null);
    setItems([]);
    setUrl(baseUrl);
  }, [baseUrl]);

  useEffect(() => {
    if (preLoadedItem) {
      setSelectedItem(preLoadedItem);
    }
    if (url) {
      getPartnersListApi.executeQuery();
    }
    // eslint-disable-next-line
  }, [url]);

  return (
    <div className={Style.inputFormContainer}>
      <div
        className={
          error ? Style.customSelectContainerError : Style.customSelectContainer
        }
        onClick={(e) => {
          const distance = calculateDistanceBetweenElementAndPageBottom(
            e.target
          );
          if (distance > 350) {
            setCheckDistance(true);
          } else {
            setCheckDistance(false);
          }
          setShowDropdown(!showDropdown);
        }}
      >
        {error && <div className={Style.errorMessage}>{error}</div>}
        {showDropdown ? (
          <>
            <span></span>
            <div
              className={
                checkDistance === true
                  ? Style.customSelectDropdownTop
                  : Style.customSelectDropdownBottom
              }
              onScroll={async (e) => {
                const element = e.target;
                let lastScrollTop = 0;

                element.onscroll = async (e) => {
                  if (element.scrollTop < lastScrollTop) {
                    return;
                  }
                  lastScrollTop =
                    element.scrollTop <= 0 ? 0 : element.scrollTop;
                  if (
                    element.scrollTop + element.offsetHeight >=
                    element.scrollHeight
                  ) {
                    if (!getPartnersListApi.isLoading && url) {
                      // getPartnersListApi.executeQuery();
                      setUrl(nextUrl);
                    }
                  }
                };
              }}
              onMouseLeave={() => {
                setShowDropdown(!showDropdown);
              }}
            >
              {/* Items */}
              {createOptions(items)}

              {/* Loader */}
              {getPartnersListApi.isLoading && (
                <div className={Style.customSelectOption}>
                  <TailSpin height={20} />
                </div>
              )}
            </div>
          </>
        ) : (
          <span style={{ color: selectedItem ? null : "#6f6e6e" }}>
            {selectedItem?.label || placeholder}
          </span>
        )}
        <div className={Style.customSelectArrow}></div>
      </div>
    </div>
  );
};
export default InputSelectAsync;
