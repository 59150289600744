import React, { useEffect, useState } from "react";

// Libraries
import { useNavigate, Link } from "react-router-dom";
import { InputField, Button } from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query/";
import { PermissionRoute } from "@hybris-software/use-auth";
import ViewLoader from "../../../Components/Commons/ViewLoader/ViewLoader";

// Icons
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";

// Images
import Logo from "./assets/logo-extended.svg";

// Styles
import Style from "./Login.module.css";

const Login = () => {
  const navigate = useNavigate();
  const [messageError, setMessageError] = useState("");

  useEffect(() => {
    document.title = "Black Minter | Login";
  }, []);

  const form = useForm({
    inputs: {
      email: {
        nature: "email",
      },
      password: {
        required: true,
      },
    },
  });

  const loginApi = useQuery({
    url: "auth/login/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      navigate("/");
    },
    onError: (error) => {
      if (error.response.status === 400) {
        setMessageError("Unable to log in with provided credentials");
      } else {
        setMessageError(
          "There may be a problem with the servers, please contact the administrator or try again later."
        );
      }
    },
  });

  return (
    <PermissionRoute
      loader={<ViewLoader />}
      minimumLoadingTime={2000}
      forLoggedUser={false}
      unAuthorizedAction={() => {
        navigate("/");
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <div className={Style.mainContainer}>
        {/* Logo */}
        <div className={Style.logoContainer}>
          <img src={Logo} alt="logo" width={150}></img>
        </div>

        {/* Login Form */}
        <div className={Style.loginContainer}>
          <div className={Style.loginHeader}>Login</div>
          <form>
            <InputField
              autoComplete="on"
              baseClassName={Style.baseClassInput}
              errorMessageClassName={Style.errorMessageClassName}
              errorClassName={Style.errorClassName}
              successClassName={Style.successClassName}
              errorIconVisibility={false}
              icon={
                <div className={Style.inputIcon}>
                  <MdEmail />
                </div>
              }
              placeholder="Email"
              {...form.getInputProps("email")}
            />

            <InputField
              baseClassName={Style.baseClassInput}
              errorMessageClassName={Style.errorMessageClassName}
              errorClassName={Style.errorClassName}
              successClassName={Style.successClassName}
              errorIconVisibility={false}
              showPasswordIconVisibility={true}
              icon={
                <div className={Style.inputIcon}>
                  <RiLockPasswordFill />
                </div>
              }
              type="password"
              placeholder="Password"
              {...form.getInputProps("password")}
            />
          </form>

          {/* Forgot Password */}
          <div className={Style.forgotPassword}>
            <Link
              to="/auth/forgot-password"
              className={Style.forgotPasswordText}
            >
              Forgot Password?
            </Link>
          </div>

          <Button
            style={{ display: "flex" }}
            disabled={loginApi.isLoading || !form.isValid()}
            onClick={() => {
              setMessageError(null);
              loginApi.executeQuery(form.getApiBody());
            }}
            isLoading={loginApi.isLoading}
          >
            Login
          </Button>
          <div className={Style.errorMessage}>
            {messageError && messageError}
          </div>
        </div>
      </div>
    </PermissionRoute>
  );
};

export default Login;
