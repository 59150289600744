import React, { useState, useEffect, useMemo } from "react";

// Components
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import StatisticsBox from "../../Components/Commons/StatisticsBox/StatisticsBox";
import NoDataLoaded from "../../Components/Commons/NoDataLoaded/NoDataLoaded";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import GeneralTable from "../../Components/Commons/GeneralTable/GeneralTable";
import LineChart from "../../Components/Commons/LineChart/LineChart";
import SlideLoader from "../../Components/Commons/SlideLoader/SlideLoader";
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";

// Utils
import detectIfIsImageFromFileName from "../../Utils/detectIfIsImageFromFileName";

// Libraries
import { useNavigate } from "react-router-dom";
import { PermissionRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { Row, Col } from "@hybris-software/ui-kit";

// Styles
import Style from "./Dashboard.module.css";
import tableStyle from "../../Assets/css/Table.module.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const [status, setStatus] = useState("all");
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState(null);
  const [sortedBy, setSortedBy] = useState("name");
  const [sortedType, setSortedType] = useState("asc");
  const [chartsData, setChartsData] = useState(null);
  const [switchChart, setSwitchChart] = useState(true);

  // API Calls
  const mintedNFT = useQuery({
    url: "dashboard/minted-nfts/",
    method: "GET",
    executeImmediately: true,
  });

  const totalCost = useQuery({
    url: "dashboard/total-cost/",
    method: "GET",
    executeImmediately: true,
  });

  const gasPrice = useQuery({
    url: "dashboard/gas-price/",
    method: "GET",
    executeImmediately: true,
  });

  const walletBalance = useQuery({
    url: "dashboard/wallet-balance/",
    method: "GET",
    executeImmediately: true,
  });

  const chartsDatas = useQuery({
    url: "dashboard/minting-analytics/",
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      setChartsData(response.data);
    },
  });

  const partnersApi = useQuery({
    url: "dashboard/active-partners/",
    method: "GET",
    executeImmediately: true,
  });

  const tableApi = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      console.clear();
      setTableData(response.data);
    },
  });

  useEffect(() => {
    setUrl(
      `dashboard/nfts/?status=${status}&search=${search}&field=${sortedBy}&sort=${sortedType}`
    );
  }, [status, search, sortedBy, sortedType]);

  useEffect(() => {
    if (url) {
      tableApi.executeQuery();
    }
    //eslint-disable-next-line
  }, [url]);

  // Table Columns
  const columns = useMemo(
    () => [
      {
        Header: "NFT #",
        field: "name",
        accessor: (row) => {
          return (
            <div
              style={{
                width: "100%",
                cursor: "pointer",
                textAlign: "left",
                padding: "5px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                navigate(`/edit-nft?id=${row.id}`);
              }}
            >
              {detectIfIsImageFromFileName(row.file) === true ? (
                <>
                  <div
                    style={{
                      borderRadius: "6px",
                      width: "30px",
                      height: "30px",
                      backgroundImage: `url(${row.file})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center"
                    }}
                  ></div>
                  <span style={{ marginLeft: "10px" }}>{row.name}</span>
                </>
              ) : (
                <>
                  <span
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "white",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    NA
                  </span>
                  <span style={{ marginLeft: "10px" }}>{row.name}</span>
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: "PARTNER",
        field: "partner",
        accessor: "partner",
      },
      {
        Header: "LAST MINTED",
        field: "last_minted",
        accessor: (row) => {
          return row.last_minted !== null ? row.last_minted : "-";
        },
      },
      {
        Header: "STATUS",
        field: "status",
        width: 500,
        minWidth: 500,
        accessor: (row) => {
          if (row.status === "DRAFT") {
            return (
              <div className={tableStyle.badgeContainer}>
                <div className={tableStyle.draft}>Draft</div>
              </div>
            );
          } else if (row.status === "MINTED") {
            return (
              <div className={tableStyle.badgeContainer}>
                <div className={tableStyle.minted}>
                  {row.minted} / {row.total} Minted
                </div>
              </div>
            );
          } else if (row.status === "MINTING") {
            return (
              <div className={tableStyle.badgeContainer}>
                <div className={tableStyle.minting}>
                  {row.minted} / {row.total} Minted
                </div>
              </div>
            );
          }
        },
      },
    ],
    [navigate]
  );

  useEffect(() => {
    document.title = "Black Minter | Dashboard";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      apiLoading={
        mintedNFT.isLoading ||
        totalCost.isLoading ||
        gasPrice.isLoading ||
        walletBalance.isLoading ||
        chartsDatas.isLoading ||
        partnersApi.isLoading
      }
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <main>
        <ViewContainer title="Dashboard">
          <section className={Style.statisticsBoxContainer}>
            {mintedNFT.response ? (
              <StatisticsBox
                color="#05c3fb"
                timeLabel="Last week"
                percentage={mintedNFT.response.data.percentage_difference_nfts}
                type="line"
                title="Minted NFTs"
                amount={
                  mintedNFT.response.data.minted_nfts +
                  "/" +
                  mintedNFT.response.data.total_nfts
                }
                data={mintedNFT.response.data.last_seven_days.data}
                labels={mintedNFT.response.data.last_seven_days.label}
                increase={mintedNFT.response.data.variation}
              />
            ) : (
              <NoDataLoaded />
            )}
            {totalCost.response ? (
              <StatisticsBox
                color="#4ecc48"
                timeLabel="Last week"
                percentage={totalCost.response.data.percentage_difference_cost}
                type="line"
                title="Total Cost"
                amount={totalCost.response.data.total_cost}
                postCurrency="eth"
                data={totalCost.response.data.last_seven_days.data}
                labels={totalCost.response.data.last_seven_days.label}
                increase={totalCost.response.data.variation}
              />
            ) : (
              <NoDataLoaded />
            )}

            {gasPrice.response ? (
              <StatisticsBox
                color="#ec82ef"
                timeLabel="Last week"
                percentage={
                  gasPrice.response.data.percentage_difference_gas_price
                }
                type="line"
                title="Last Gas Price"
                amount={gasPrice.response.data.last_gas_price}
                postCurrency="Gwei"
                data={gasPrice.response.data.last_seven_days.data}
                labels={gasPrice.response.data.last_seven_days.label}
                increase={gasPrice.response.data.variation}
              />
            ) : (
              <NoDataLoaded />
            )}

            {walletBalance.response ? (
              <StatisticsBox
                color="#f7b731"
                timeLabel="Last week"
                percentage={
                  walletBalance.response.data
                    .percentage_difference_wallet_balance
                }
                type="line"
                title="Wallet Balance"
                amount={walletBalance.response.data.wallet_balance}
                postCurrency="eth"
                data={walletBalance.response.data.last_seven_days.data}
                labels={walletBalance.response.data.last_seven_days.label}
                increase={walletBalance.response.data.variation}
              />
            ) : (
              <NoDataLoaded />
            )}
          </section>
          <section className={Style.dashboardBody}>
            <Row
              columnGap={{
                horizontal: {
                  xs: 20,
                  sm: 20,
                  md: 20,
                  lg: 20,
                  xl: 20,
                  xxl: 20,
                },
                vertical: {
                  xs: 20,
                  sm: 20,
                  md: 20,
                  lg: 20,
                  xl: 20,
                  xxl: 20,
                },
              }}
            >
              <Col md={8} sm={12}>
                <ComponentContainer
                  title="Minting Analytics"
                  filters={[
                    {
                      name: "Minted",
                      onClick: async () => {
                        setSwitchChart(true);
                      },
                      default: true,
                    },
                    {
                      name: "Cost",
                      onClick: async () => {
                        setSwitchChart(false);
                      },
                    },
                  ]}
                >
                  {chartsData && (
                    <LineChart
                      color="#8700e0"
                      labels={chartsData.months}
                      data={switchChart ? chartsData.minted : chartsData.cost}
                    />
                  )}
                </ComponentContainer>
              </Col>
              <Col md={4} sm={12}>
                <ComponentContainer title="Active Partners" minHeight="100%">
                  {partnersApi.response &&
                    partnersApi.response.data.map((partner, index) => {
                      return (
                        <SlideLoader
                          key={index}
                          label={partner.name}
                          partial={partner.minted_nfts}
                          total={partner.total_nfts}
                          completed={
                            (partner.minted_nfts / partner.total_nfts) * 100
                          }
                        />
                      );
                    })}
                </ComponentContainer>
              </Col>
            </Row>
          </section>
          <section className={Style.table}>
            <ComponentContainer
              margin="20px 0px 0px 0px"
              title="Last NFTs Minted"
              filters={[
                {
                  name: "All",
                  default: true,
                  onClick: () => {
                    setStatus("ALL");
                  },
                },
                {
                  name: "Draft",
                  onClick: () => {
                    setStatus("DRAFT");
                  },
                },
                {
                  name: "Minting",
                  onClick: () => {
                    setStatus("MINTING");
                  },
                },
                {
                  name: "Minted",
                  onClick: () => {
                    setStatus("MINTED");
                  },
                },
              ]}
            >
              {tableData && (
                <GeneralTable
                  data={tableData}
                  columns={columns}
                  setUrl={setUrl}
                  setSearch={setSearch}
                  isLoading={tableApi.isLoading}
                  sortedBy={sortedBy}
                  setSortedBy={setSortedBy}
                  sortedType={sortedType}
                  setSortedType={setSortedType}
                />
              )}
            </ComponentContainer>
          </section>
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default Dashboard;
