import React from "react";

// Components
import NftCard from "../NftCard/NftCard";
import Loader from "../Loader/Loader";

// Libraries
import { useNavigate } from "react-router-dom";
import { Button } from "@hybris-software/ui-kit";

// Icons
import { GrFormAdd } from "react-icons/gr";

// Styles
import Style from "./NftGrid.module.css";

const NftGrid = ({ data, setUrl, isLoading, setSearch, role }) => {
  const navigate = useNavigate();

  return (
    <div className={Style.mainContainer}>
      {role !== "Viewer" && (
        <div
          className={Style.addButtonMobile}
          onClick={() => {
            navigate("/create-nft");
          }}
        >
          <span>
            <GrFormAdd className={Style.addButtonIcon} />
          </span>
          <span className={Style.addButtonTextMobile}>NEW NFT</span>
        </div>
      )}

      <div className={Style.filterMenuContainer}>
        <div className={Style.searchBarContainer}>
          <input
            type="text"
            className={Style.inputSearch}
            placeholder="Search..."
            onChange={(e) => {
              if (isLoading === false) {
                setSearch(e.target.value);
              }
            }}
          />
        </div>
        <div className={Style.buttonsContainer}>
          {role !== "Viewer" && (
            <div
              className={Style.addButton}
              onClick={() => {
                navigate("/create-nft");
              }}
            >
              <span>
                <GrFormAdd className={Style.addButtonIcon} />
              </span>
              <span className={Style.addButtonText}>NEW NFT</span>
            </div>
          )}
        </div>
      </div>

      {isLoading || !data ? (
        <Loader height="70vh" />
      ) : (
        <>
          <div className={Style.cardsContainer}>
            {data.results.map((item) => {
              return (
                <NftCard
                  key={item.id}
                  name={item.name}
                  partner={item.partner}
                  status={item.status}
                  statusText={item.minted + "/" + item.quantity}
                  file={item.file}
                  role={role}
                  onClick={() => {
                    if (role === "Viewer") {
                      navigate("/view-nft?id=" + item.id);
                    } else {
                      navigate("/edit-nft?id=" + item.id);
                    }
                  }}
                />
              );
            })}
          </div>
          <div className={Style.bottomMenuTable}>
            <div className={Style.pagination}>
              <div className={Style.entries}>
                {"Showing " +
                  data.from +
                  " to " +
                  data.to +
                  " of " +
                  data.count +
                  " entries"}
              </div>
              <div className={Style.paginationButtons}>
                <Button
                  disabled={data.links.previous === null || isLoading}
                  buttonClassName={Style.buttonLeft}
                  disabledClassName={Style.buttonDisabled}
                  onClick={() => {
                    if (data?.links.previous !== null) {
                      setUrl(
                        data.links.previous.replace("http://", "https://")
                      );
                    }
                  }}
                >
                  Previous
                </Button>
                <div className={Style.paginationItem}>{data.page || "-"}</div>
                <Button
                  disabled={
                    data === null || data.links.next === null || isLoading
                  }
                  buttonClassName={Style.buttonRight}
                  disabledClassName={Style.buttonDisabled}
                  onClick={() => {
                    if (data?.links.next !== null) {
                      setUrl(data.links.next.replace("http://", "https://"));
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NftGrid;
