import React from 'react';

// Layouts
import AuthLayout from './Layouts/AuthLayout/AuthLayout';
import MainLayout from './Layouts/MainLayout/MainLayout';

// Components
import Login from './Components/Auth/Login/Login';
import ForgotPassword from './Components/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword/ResetPassword';

// Views
import Dashboard from './Views/Dashboard/Dashboard';
import Partners from './Views/Partners/Partners';
import CreatePartner from './Views/CreatePartner/CreatePartner';
import EditPartner from './Views/EditPartner/EditPartner';
import SmartContracts from './Views/SmartContracts/SmartContracts';
import Users from './Views/Users/Users';
import Wallet from './Views/Wallet/Wallet';
import CreateUser from './Views/CreateUser/CreateUser';
import EditUser from './Views/EditUser/EditUser';
import Profile from './Views/Profile/Profile';
import CreateSmartContract from './Views/CreateSmartContract/CreateSmartContract';
import EditSmartContract from './Views/EditSmartContract/EditSmartContract';
import CreateNft from './Views/CreateNft/CreateNft';
import NFTs from './Views/NFTs/NFTs';
import EditNft from './Views/EditNft/EditNft';
import ViewNft from './Views/ViewNft/ViewNft';
import ConfigView from './Views/Config/Config';

// Libraries
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ApiProvider } from "@hybris-software/use-query";
import { AuthProvider } from "@hybris-software/use-auth";
import { ThemeProvider } from "@hybris-software/ui-kit";
import axios from 'axios';

// Styles
import Theme from './Assets/css/Theme.module.css';

// Config
import Config from './config';

// Utils
import generateJwtApiClient from './Utils/apiClient';

const authUrl = "user-info/"

function App() {
  const apiClient = generateJwtApiClient({
    baseUrl: Config.API_BASE_URL,
    authorizationHeader: "Authorization",
    authorizationPrefix: "Bearer ",
    accessTokenLocalStorageKey: "accessToken",
    refreshTokenLocalStorageKey: "refreshToken",
    refreshTokenFunction: ({ refreshToken }) => {
      return axios.post(`${Config.API_BASE_URL}auth/token/refresh/`, {
        refresh: refreshToken
      }).then((response) => {
        return [response.data.access, refreshToken];
      })
    },
  })

  return (
    <ThemeProvider theme={{
      container: {
        containerClassName: Theme.containerStyle,
      },
      row: {
        rowClassName: Theme.rowStyle,
        columnGap: {
          horizontal: {
            xs: 20,
            sm: 20,
            md: 20,
            lg: 20,
            xl: 20,
            xxl: 20,
          },
          vertical: {
            xs: 10,
            sm: 10,
            md: 10,
            lg: 10,
            xl: 10,
            xxl: 10,
          }
        },
      },
      col: {
        columnStyle: Theme.columnStyle,
      },
      inputField: {
        baseClassName: Theme.inputFieldStyle,
        errorClassName: Theme.inputFieldErrorStyle,
        successClassName: Theme.inputFieldStyle,
        errorMessageClassName: Theme.errorMessageClassName,
        successIconVisibility: false,
        errorIconVisibility: false,
      },
      button: {
        buttonClassName: Theme.buttonCommon,
        buttonDisabledClassName: Theme.buttonCommonDisabled,
      },
    }} >

      <ApiProvider apiClient={apiClient}>
        <AuthProvider apiClient={apiClient} authUrl={authUrl}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<div>404</div>} />
              <Route path='/auth' element={<AuthLayout />}>
                <Route path='login' element={<Login />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route path='reset-password' element={<ResetPassword />} />
                {/* Add auth route here */}
              </Route>
              <Route path='/' element={<MainLayout />}>
                {/* Dashboard */}
                <Route path='' element={<Dashboard />} />
                {/* Nft */}
                <Route path='create-nft' element={<CreateNft />} />
                <Route path='edit-nft' element={<EditNft />} />
                <Route path='view-nft' element={<ViewNft />} />
                <Route path='nfts' element={<NFTs />} />
                {/* Smart Contract */}
                <Route path='smart-contracts' element={<SmartContracts />} />
                <Route path='create-smart-contract' element={<CreateSmartContract />} />
                <Route path='edit-smart-contract' element={<EditSmartContract />} />
                {/* Partner */}
                <Route path='create-partner' element={<CreatePartner />} />
                <Route path='edit-partner' element={<EditPartner />} />
                <Route path='partners' element={<Partners />} />
                {/* Settings */}
                <Route path='platform-wallet' element={<Wallet />} />
                <Route path='config' element={<ConfigView />} />
                {/* User */}
                <Route path='create-user' element={<CreateUser />} />
                <Route path='edit-user' element={<EditUser />} />
                <Route path='users' element={<Users />} />
                <Route path='profile' element={<Profile />} />
                {/* Add more routes here */}
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ApiProvider>
    </ThemeProvider>
  );
}

export default App;
