import React, { useEffect, useState } from "react";

// Components
import NftGrid from "../../Components/Commons/NftGrid/NftGrid";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";

// Libraries
import useQuery from "@hybris-software/use-query";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate, useLocation } from "react-router-dom";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

const NFTs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [url, setUrl] = useState("");
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("Viewer");

  const getNFTList = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
  });

  useEffect(() => {
    if (url) {
      getNFTList.executeQuery();
    }
    // eslint-disable-next-line
  }, [url]);

  useEffect(() => {
    setUrl(`nfts?page_size=12&search=${search}`);
  }, [search]);

  useEffect(() => {
    document.title = "Black Minter | NFTs";
  }, []);
  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        setRole(response.data.role);
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <ViewContainer title="NFTs">
        <NftGrid
          data={getNFTList.response?.data}
          isLoading={getNFTList.isLoading}
          setUrl={setUrl}
          setSearch={setSearch}
          role={role}
        />
      </ViewContainer>
    </PermissionRoute>
  );
};

export default NFTs;
