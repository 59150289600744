import React, { useState } from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";

// Icons
import { MdClose } from "react-icons/md";

// Styles
import Style from "./GeneralPopup.module.css";

const GeneralPopup = ({
  showIcon,
  maxWidth,
  height,
  setPopup,
  title,
  text,
  buttonColor,
  buttonOnClick,
  buttonText,
  buttonLoad,
  secondaryButtonColor,
  secondaryButtonText,
  secondaryButtonOnClick,
  secondaryButtonIsLoading,
}) => {
  const [buttonLoadState, setButtonLoadState] = useState(false);

  return (
    <div className={Style.layover}>
      <div
        className={Style.generalPopup}
        style={{
          maxWidth: maxWidth,
          height: height,
        }}
      >
        <div className={Style.generalPopupHeader}>
          <span className={Style.generalPopupIcon}>
            {showIcon === true && (
              <MdClose
                onClick={() => {
                  setPopup(null);
                }}
              />
            )}
          </span>
        </div>

        <div className={Style.generalPopupBody}>
          <div className={Style.generalPopupTitle}>{title}</div>
          <div className={Style.generalPopupText}>{text}</div>
          <div className={Style.buttonsContainer}>
            <Button
              isLoading={buttonLoadState && buttonLoad}
              style={{
                backgroundColor: buttonColor ? buttonColor : null,
                marginBottom: 20,
              }}
              onClick={() => {
                setButtonLoadState(true);
                buttonOnClick();
              }}
            >
              {buttonText}
            </Button>
            {secondaryButtonText && (
              <Button
                isLoading={secondaryButtonIsLoading}
                style={{
                  backgroundColor: secondaryButtonColor
                    ? secondaryButtonColor
                    : null,
                  marginBottom: 20,
                }}
                onClick={() => {
                  secondaryButtonOnClick();
                }}
              >
                {secondaryButtonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralPopup;
