import React, { useState, useEffect } from "react";

// Componentns
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import BottomMenu from "../../Components/Commons/BottomMenu/BottomMenu";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { InputField, Row, Col } from "@hybris-software/ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Styles
import Style from "./EditPartner.module.css";

const EditPartner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const partnerId = params.get("id");

  // Queries
  const editPartnerApi = useQuery({
    url: `partners/${partnerId}/`,
    method: "PUT",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={true}
          setPopup={setPopup}
          title="Success!"
          text="Partner Edit Successfully"
          buttonOnClick={() => {
            setPopup(null);
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error.response.data);
      } else if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title="Error!"
            text="Partner Not Found. Partner may have been deleted"
            buttonOnClick={() => {
              navigate("/partners");
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response?.data?.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              navigate("/partners");
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const changePartnerStatusApi = useQuery({
    url: `partners/${partnerId}/change-status/`,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="Partner Status Changed Successfully"
          buttonOnClick={() => {
            setPopup(null);
            getPartnerDetailsApi.executeQuery();
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={false}
            setPopup={setPopup}
            title="Error!"
            text="Partner Not Found. Partner may have been deleted"
            buttonOnClick={() => {
              setPopup(null);
              navigate("/partners");
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message}
                <br />
                {error.response?.data?.error && error.response?.data?.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const getPartnerDetailsApi = useQuery({
    url: `partners/${partnerId}/`,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      form.getInputProps("name").setValue(response.data.name || "");
      form.getInputProps("email").setValue(response.data.email || "");
      form.getInputProps("key").setValue(response.data.key || "");
    },
    onError: (error) => {
      navigate("/partners");
    },
  });

  const deletePartnerApi = useQuery({
    url: `partners/${partnerId}/`,
    method: "DELETE",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(null);
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="Partner Deleted Successfully"
          buttonOnClick={() => {
            setPopup(null);
            navigate("/partners");
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title="Error!"
            text="Partner Not Found. Partner may have been deleted"
            buttonOnClick={() => {
              setPopup(null);
              navigate("/partners");
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  // Form
  const form = useForm({
    inputs: {
      name: {
        required: true,
      },
      email: {
        validator: (value) => {
          const re =
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-next-line

          if (value !== "") {
            if (re.test(String(value).toLowerCase())) {
              return [true, null];
            } else {
              return [false, "Invalid email"];
            }
          } else {
            return [true, null];
          }
        },
      },
      key: {},
    },
  });

  useEffect(() => {
    document.title = "Black Minter | Edit Partner";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={getPartnerDetailsApi.isLoading}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        {popup && popup}
        <ViewContainer
          title="Edit Partner"
          badge={getPartnerDetailsApi.response?.data.status}
          badgeStatus={getPartnerDetailsApi.response?.data.status}
          buttonText={
            getPartnerDetailsApi.response?.data.status === "ACTIVE"
              ? "DISABLE"
              : "ACTIVATE"
          }
          buttonOnClick={() => {
            if (getPartnerDetailsApi.response?.data.status === "ACTIVE") {
              changePartnerStatusApi.executeQuery({
                status: "DISABLED",
              });
            } else {
              changePartnerStatusApi.executeQuery({
                status: "ACTIVE",
              });
            }
          }}
          buttonIsLoading={changePartnerStatusApi.isLoading}
        >
          <ComponentContainer title="Partner" minHeight="500px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Name</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. Gucci"
                  {...form.getInputProps("name")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Email</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. mariorossi@gmail.com"
                  {...form.getInputProps("email")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>API Key</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={true}
                  style={{ backgroundColor: "#3c3c3c" }}
                  placeholder="eb777a49-e687-4f8c-a85d-ef41d07b9d5e"
                  {...form.getInputProps("key")}
                />
              </Col>
            </Row>
          </ComponentContainer>
          <BottomMenu
            createButtonText="UPDATE"
            createIsLoading={editPartnerApi.isLoading}
            createButtonOnClick={() => {
              if (form.validate()) {
                editPartnerApi.executeQuery(form.getApiBody());
              }
            }}
            deleteButtonText="Delete Partner"
            deleteButtonOnClick={() => {
              setPopup(
                <GeneralPopup
                  showIcon={true}
                  setPopup={setPopup}
                  title="Are you sure?"
                  text="This action cannot be undone."
                  buttonOnClick={() => {
                    deletePartnerApi.executeQuery();
                  }}
                  buttonLoad={true}
                  buttonText="Delete"
                  buttonColor="#008000d1"
                  buttonIsLoading={true}
                  secondaryButtonText="Cancel"
                  secondaryButtonOnClick={() => {
                    setPopup(null);
                  }}
                  secondaryButtonColor="#ff0000d1"
                />
              );
            }}
          ></BottomMenu>
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default EditPartner;
