import React, { useState, useEffect } from "react";

// Components
import BottomMenu from "../../Components/Commons/BottomMenu/BottomMenu";
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import InputSelectAsync from "../../Components/Commons/InputSelectAsync/InputSelectAsync";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";
import InputFile from "../../Components/Commons/InputFile/InputFile";
import LoadFilePopup from "../../Components/Commons/LoadFilePopup/LoadFilePopup";
import GeneralTable from "../../Components/Commons/GeneralTable/GeneralTable";

// Libraries
import { Row, Col, InputField, TextField } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";
import { useNavigate, useLocation } from "react-router-dom";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Config
import Config from "../../config";

// Styles
import Style from "./EditNft.module.css";
import tableStyle from "../../Assets/css/Table.module.css";

const EditNft = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const [show, setShow] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [preloadedContract, setPreloadedContract] = useState(null);
  const [fileUpdated, setFileUpdated] = useState(false);
  const [notEditable, setNotEditable] = useState(false);

  // Table
  const [tableData, setTableData] = useState([]);
  const [url, setUrl] = useState(null);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState(null);
  const [sortedBy, setSortedBy] = useState("progressive");
  const [sortedType, setSortedType] = useState("asc");

  const params = new URLSearchParams(window.location.search);
  const nftId = params.get("id");

  const columns = [
    {
      Header: "PROGRESSIVE",
      field: "progressive",
      accessor: "progressive",
    },
    {
      Header: "TRANSACTION ID",
      field: "transaction_id",
      accessor: "transaction_id",
    },
    {
      Header: "STATUS",
      field: "status",
      width: 500,
      minWidth: 500,
      accessor: (row) => {
        if (row.status === "MINTED") {
          return (
            <div className={tableStyle.badgeContainer}>
              <div className={tableStyle.minted}>Minted</div>
            </div>
          );
        } else if (row.status === "TRANSFERRED") {
          return (
            <div className={tableStyle.badgeContainer}>
              <div className={tableStyle.minting}>Transferred</div>
            </div>
          );
        }
      },
    },
  ];

  const editNftApi = useQuery({
    url: `nfts/${nftId}/`,
    method: "PUT",
    executeImmediately: false,
    clientOptions: {
      timeout: 120000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => setUploaded(progressEvent.loaded),
    },
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="NFT Created Successfully"
          buttonOnClick={() => {
            setPopup(null);
            getNftDetailsApi.executeQuery();
          }}
          buttonText="Continue"
          width={400}
        />
      );
      setShow(false);
    },
    onError: (error) => {
      setShow(false);
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error.response.data);
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const getNftDetailsApi = useQuery({
    url: `nfts/${nftId}/`,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      setNotEditable(response.data.status === "DRAFT" ? false : true);
      form.getInputProps("name").setValue(response.data.name || "");
      form
        .getInputProps("quantity")
        .setValue(response.data.quantity.toString() || "");
      form
        .getInputProps("price")
        .setValue(response.data.price.toString() || "");
      form
        .getInputProps("description")
        .setValue(response.data.description || "");

      const tmpFile = [
        {
          file: response.data.file,
          name: response.data.file_name,
          size: response.data.file_size,
          loaded: true,
        },
      ];

      form.getInputProps("file").setValue(tmpFile);
      form.getInputProps("partner").setValue(response.data.partner.value || "");
      form
        .getInputProps("contract")
        .setValue(response.data.contract.value || "");
      setPreloadedContract(response.data.contract);
      form
        .getInputProps("minting_mode")
        .setValue(response.data.minting_mode.value || "");
      form
        .getInputProps("wallet_address")
        .setValue(response.data.wallet_address || "");
      form.getInputProps("private_key").setValue(response.data.private_key);
    },
    onError: (error) => {
      navigate("/nfts");
    },
  });

  const deleteNftApi = useQuery({
    url: `nfts/${nftId}/`,
    method: "DELETE",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(null);
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="NFT Deleted Successfully"
          buttonOnClick={() => {
            setPopup(null);
            navigate("/nfts");
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title="Error!"
            text="NFT Not Found. NFT may have been deleted"
            buttonOnClick={() => {
              setPopup(null);
              navigate("/nfts");
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const changeNftStatusApi = useQuery({
    url: `nfts/${nftId}/mint/`,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="NFT Successfully Minted"
          buttonOnClick={() => {
            setPopup(null);
            getNftDetailsApi.executeQuery();
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        setPopup(
          <GeneralPopup
            showIcon={false}
            setPopup={setPopup}
            title="Error!"
            text="NFT Not Found. NFT may have been deleted"
            buttonOnClick={() => {
              setPopup(null);
              navigate("/nfts");
            }}
            buttonText="Continue"
            width={400}
          />
        );
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message}
                <br />
                {error.response?.data?.error && error.response?.data?.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const tableApi = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      console.clear();
      setTableData(response.data);
    },
  });

  useEffect(() => {
    setUrl(
      `nfts/${nftId}/items/?status=${status}&search=${search}&field=${sortedBy}&sort=${sortedType}`
    );
    // eslint-disable-next-line
  }, [status, search, sortedBy, sortedType]);

  useEffect(() => {
    if (url) {
      tableApi.executeQuery();
    }
    //eslint-disable-next-line
  }, [url]);

  const form = useForm({
    inputs: {
      name: {
        required: true,
      },
      quantity: {
        required: true,
        formatter: (value) => {
          return value.replace(/[^0-9]/g, "");
        },
        validator: (value) => {
          if (value < 1) {
            return [false, "Supply must be greater than 0"];
          } else {
            return [true, null];
          }
        },
      },
      price: {
        required: true,
        formatter: (value) => {
          return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        },
        validator: (value) => {
          if (value < 1) {
            return [false, "Price must be greater than 0"];
          } else {
            return [true, null];
          }
        },
      },
      description: {
        required: true,
      },
      file: {
        required: true,
      },
      partner: {
        required: true,
      },
      contract: {
        required: true,
      },
      minting_mode: {
        required: true,
      },
      wallet_address: {
        required: true,
      },
      private_key: {},
    },
  });

  useEffect(() => {
    if (form.getInputProps("partner").value) {
      setContractUrl(
        `contracts?minimal=true&partner=${form.getInputProps("partner").value}`
      );
      if (!firstLoading) {
        form.getInputProps("contract").setValue(null);
        setPreloadedContract(null);
      }
      setFirstLoading(false);
    }
    // eslint-disable-next-line
  }, [form.getInputProps("partner").value]);

  useEffect(() => {
    document.title = "Black Minter | Edit NFT";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        {popup && popup}
        {form.getInputProps("file").value && uploaded && show && (
          <LoadFilePopup
            size={form.getInputProps("file").value[0].size}
            uploaded={uploaded}
          />
        )}
        <ViewContainer
          title="Edit NFT"
          badgeStatus={getNftDetailsApi.response?.data.status}
          badgeCapitalizeFirstLetter={
            getNftDetailsApi.response?.data.status === "MINTING" ? false : true
          }
          badge={
            getNftDetailsApi.response?.data.status === "MINTING"
              ? getNftDetailsApi.response?.data.minted +
                "/" +
                getNftDetailsApi.response?.data.quantity +
                " " +
                "Minted"
              : getNftDetailsApi.response?.data.status
          }
          buttonText={
            getNftDetailsApi.response?.data.status === "DRAFT" && "MINT NOW"
          }
          buttonOnClick={() => {
            if (getNftDetailsApi.response?.data.status === "DRAFT") {
              changeNftStatusApi.executeQuery();
            }
          }}
          buttonIsLoading={changeNftStatusApi.isLoading}
        >
          <ComponentContainer title="NFT" minHeight="500px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Name</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={notEditable}
                  style={{ backgroundColor: notEditable && "#3c3c3c" }}
                  placeholder="Ex. Eagle NFT"
                  {...form.getInputProps("name")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Supply</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={notEditable}
                  style={{ backgroundColor: notEditable && "#3c3c3c" }}
                  placeholder="Ex. 10"
                  {...form.getInputProps("quantity")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Price (ETH)</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={notEditable}
                  style={{ backgroundColor: notEditable && "#3c3c3c" }}
                  placeholder="Ex. 1.2"
                  {...form.getInputProps("price")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Description</h5>
              </Col>
              <Col md={8} sm={12}>
                <TextField
                  readOnly={notEditable}
                  style={{ backgroundColor: notEditable && "#3c3c3c" }}
                  rows={10}
                  baseClassName={Style.textArea}
                  successClassName={Style.textArea}
                  errorClassName={Style.textAreaError}
                  placeholder="Provide a description of your NFT, this will be part of the public final NFT"
                  {...form.getInputProps("description")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>NFT Upload</h5>
              </Col>
              <Col md={8} sm={12}>
                {getNftDetailsApi.response && (
                  <InputFile
                    readOnly={notEditable}
                    label="NFT Upload"
                    subLabel="Image, Video, Audio, or 3D Model"
                    name="image"
                    id="nft_image"
                    setFile={form.getInputProps("file").setValue}
                    file={form.getInputProps("file").value}
                    maxFileSize={Config.FILE_SIZE}
                    errorPopup={setPopup}
                    onChange={() => {
                      setFileUpdated(true);
                    }}
                    errorMessage={form.getInputProps("file").errorDetails}
                  />
                )}
              </Col>
            </Row>
          </ComponentContainer>
          <ComponentContainer margin="20px 0" title="Minting" minHeight="300px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Partner</h5>
              </Col>
              <Col md={8} sm={12}>
                {getNftDetailsApi.response &&
                  (notEditable ? (
                    <InputField
                      readOnly={notEditable}
                      style={{ backgroundColor: notEditable && "#3c3c3c" }}
                      value={getNftDetailsApi.response.data.partner.label}
                    />
                  ) : (
                    <InputSelectAsync
                      placeholder="Select partner..."
                      setItem={form.getInputProps("partner").setValue}
                      baseUrl="partners/?minimal=true"
                      error={form.getInputProps("partner").errorDetails}
                      preLoadedItem={getNftDetailsApi.response.data.partner}
                    />
                  ))}
              </Col>
              <Col md={4} sm={12}>
                <h5>Contract</h5>
              </Col>
              <Col md={8} sm={12}>
                {form.getInputProps("partner").value &&
                getNftDetailsApi.response ? (
                  notEditable ? (
                    <InputField
                      readOnly={notEditable}
                      style={{ backgroundColor: notEditable && "#3c3c3c" }}
                      value={getNftDetailsApi.response.data.contract.label}
                    />
                  ) : (
                    <InputSelectAsync
                      placeholder="Select contract..."
                      setItem={form.getInputProps("contract").setValue}
                      baseUrl={contractUrl}
                      error={form.getInputProps("contract").errorDetails}
                      preLoadedItem={preloadedContract}
                    />
                  )
                ) : (
                  <p style={{ fontSize: 14, marginTop: 10, marginBottom: 28 }}>
                    Select a Partner to see the contracts list
                  </p>
                )}
              </Col>
              <Col md={4} sm={12}>
                <h5>Minting Mode</h5>
              </Col>
              <Col md={8} sm={12}>
                {getNftDetailsApi.response &&
                  (notEditable ? (
                    <InputField
                      readOnly={notEditable}
                      style={{ backgroundColor: notEditable && "#3c3c3c" }}
                      {...form.getInputProps("minting_mode")}
                      value={getNftDetailsApi.response.data.minting_mode.label}
                    />
                  ) : (
                    <InputSelectAsync
                      placeholder="Select Minting Mode..."
                      setItem={form.getInputProps("minting_mode").setValue}
                      baseUrl="minting-modes/?minimal=true"
                      error={form.getInputProps("minting_mode").errorDetails}
                      preLoadedItem={
                        getNftDetailsApi.response.data.minting_mode
                      }
                    />
                  ))}
              </Col>
              <Col md={4} sm={12}>
                <h5>Wallet</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={notEditable}
                  style={{ backgroundColor: notEditable && "#3c3c3c" }}
                  placeholder="Ex. 1.2"
                  {...form.getInputProps("wallet_address")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Private Key</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={notEditable}
                  style={{ backgroundColor: notEditable && "#3c3c3c" }}
                  placeholder="Ex. 1.2"
                  {...form.getInputProps("private_key")}
                />
              </Col>
            </Row>
          </ComponentContainer>
          {getNftDetailsApi.response?.data.status !== "DRAFT" && (
            <ComponentContainer
              margin="20px 0"
              title="Items"
              minHeight="300px"
              filters={[
                {
                  name: "All",
                  default: true,
                  onClick: () => {
                    setStatus("ALL");
                  },
                },
                {
                  name: "Transferred",
                  onClick: () => {
                    setStatus("TRANSFERRED");
                  },
                },
                {
                  name: "Minted",
                  onClick: () => {
                    setStatus("MINTED");
                  },
                },
              ]}
            >
              {tableData && (
                <GeneralTable
                  data={tableData}
                  columns={columns}
                  setUrl={setUrl}
                  setSearch={setSearch}
                  isLoading={tableApi.isLoading}
                  sortedBy={sortedBy}
                  setSortedBy={setSortedBy}
                  sortedType={sortedType}
                  setSortedType={setSortedType}
                />
              )}
            </ComponentContainer>
          )}
          {getNftDetailsApi.response?.data?.status === "DRAFT" && (
            <BottomMenu
              createButtonText="UPDATE"
              createIsLoading={editNftApi.isLoading}
              createButtonOnClick={() => {
                if (form.validate()) {
                  const formData = new FormData();
                  formData.append("name", form.values.name);
                  formData.append("quantity", form.values.quantity);
                  formData.append("price", form.values.price);
                  formData.append("description", form.values.description);
                  if (fileUpdated) {
                    setShow(true);
                    formData.append(
                      "file",
                      form.values.file[0],
                      form.values.file[0].name
                    );
                  }
                  formData.append("partner", form.values.partner);
                  formData.append("contract", form.values.contract);
                  formData.append("minting_mode", form.values.minting_mode);
                  formData.append("wallet_address", form.values.wallet_address);
                  editNftApi.executeQuery(formData);
                }
              }}
              deleteButtonText="Delete NFT"
              deleteButtonOnClick={() => {
                setPopup(
                  <GeneralPopup
                    showIcon={true}
                    setPopup={setPopup}
                    title="Are you sure?"
                    text="This action cannot be undone."
                    buttonOnClick={() => {
                      deleteNftApi.executeQuery();
                    }}
                    buttonLoad={true}
                    buttonText="Delete"
                    buttonColor="#008000d1"
                    buttonIsLoading={true}
                    secondaryButtonText="Cancel"
                    secondaryButtonOnClick={() => {
                      setPopup(null);
                    }}
                    secondaryButtonColor="#ff0000d1"
                  />
                );
              }}
            ></BottomMenu>

          )}
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default EditNft;
