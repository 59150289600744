import React, { useState, useEffect } from "react";

// Components
import BottomMenu from "../../Components/Commons/BottomMenu/BottomMenu";
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import InputSelectAsync from "../../Components/Commons/InputSelectAsync/InputSelectAsync";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";
import InputFile from "../../Components/Commons/InputFile/InputFile";
import LoadFilePopup from "../../Components/Commons/LoadFilePopup/LoadFilePopup";

// Libraries
import { Row, Col, InputField, TextField } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";
import { useNavigate, useLocation } from "react-router-dom";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Config
import Config from "../../config";

// Styles
import Style from "./CreateNft.module.css";

const CreateNft = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const [show, setShow] = useState(false);

  const createNftApi = useQuery({
    url: "nfts/",
    method: "POST",
    executeImmediately: false,
    clientOptions: {
      timeout: 120000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => setUploaded(progressEvent.loaded),
    },
    onSuccess: (response) => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="NFT Created Successfully"
          buttonOnClick={() => {
            setPopup(null);
            navigate(`/edit-nft?id=${response.data.id}`);
          }}
          buttonText="Continue"
          width={400}
        />
      );
      setShow(false);
    },
    onError: (error) => {
      setShow(false);
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error.response.data);
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const form = useForm({
    inputs: {
      name: {
        required: true,
      },
      quantity: {
        required: true,
        formatter: (value) => {
          return value.replace(/[^0-9]/g, "");
        },
        validator: (value) => {
          if (value < 1) {
            return [false, "Supply must be greater than 0"];
          } else {
            return [true, null];
          }
        },
      },
      price: {
        required: true,
        formatter: (value) => {
          // Accept only float number with only one .
          return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        },
        validator: (value) => {
          if (value < 1) {
            return [false, "Price must be greater than 0"];
          } else {
            return [true, null];
          }
        },
      },
      description: {
        required: true,
      },
      file: {
        required: true,
      },
      partner: {
        required: true,
      },
      contract: {
        required: true,
      },
      minting_mode: {
        required: true,
      },
      wallet_address: {
        required: true,
      },
      private_key: {},
    },
  });

  useEffect(() => {
    if (form.getInputProps("partner").value) {
      setContractUrl(
        `contracts?minimal=true&partner=${form.getInputProps("partner").value}`
      );
    }
    // eslint-disable-next-line
  }, [form.getInputProps("partner").value]);

  useEffect(() => {
    document.title = "Black Minter | Create NFT";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        {popup && popup}
        {form.getInputProps("file").value && uploaded && show && (
          <LoadFilePopup
            size={form.getInputProps("file").value[0].size}
            uploaded={uploaded}
          />
        )}
        <ViewContainer title="Create New NFT">
          <ComponentContainer title="NFT" minHeight="500px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Name</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. Eagle NFT"
                  {...form.getInputProps("name")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Supply</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. 10"
                  {...form.getInputProps("quantity")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Price (ETH)</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. 1.2"
                  {...form.getInputProps("price")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Description</h5>
              </Col>
              <Col md={8} sm={12}>
                <TextField
                  rows={10}
                  baseClassName={Style.textArea}
                  successClassName={Style.textArea}
                  errorClassName={Style.textAreaError}
                  placeholder="Provide a description of your NFT, this will be part of the public final NFT"
                  {...form.getInputProps("description")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>NFT Upload</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputFile
                  label="NFT Upload"
                  subLabel="Image, Video, Audio, or 3D Model"
                  name="image"
                  id="nft_image"
                  setFile={form.getInputProps("file").setValue}
                  file={form.getInputProps("file").value}
                  maxFileSize={Config.FILE_SIZE}
                  errorPopup={setPopup}
                  onChange={(e) => form.getInputProps("file").setValue}
                  errorMessage={form.getInputProps("file").errorDetails}
                />
              </Col>
            </Row>
          </ComponentContainer>
          <ComponentContainer margin="20px 0" title="Minting" minHeight="300px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Partner</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputSelectAsync
                  placeholder="Select partner..."
                  setItem={form.getInputProps("partner").setValue}
                  baseUrl="partners/?minimal=true"
                  error={form.getInputProps("partner").errorDetails}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Contract</h5>
              </Col>
              <Col md={8} sm={12}>
                {form.getInputProps("partner").value ? (
                  <InputSelectAsync
                    placeholder="Select contract..."
                    setItem={form.getInputProps("contract").setValue}
                    baseUrl={contractUrl}
                    error={form.getInputProps("contract").errorDetails}
                  />
                ) : (
                  <p style={{ fontSize: 14, marginTop: 10, marginBottom: 28 }}>
                    Select a Partner to see the contracts list
                  </p>
                )}
              </Col>
              <Col md={4} sm={12}>
                <h5>Minting Mode</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputSelectAsync
                  placeholder="Select Minting Mode..."
                  setItem={form.getInputProps("minting_mode").setValue}
                  baseUrl="minting-modes/?minimal=true"
                  error={form.getInputProps("minting_mode").errorDetails}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Wallet</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. 1.2"
                  {...form.getInputProps("wallet_address")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Private Key</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. 1.2"
                  {...form.getInputProps("private_key")}
                />
              </Col>
            </Row>
          </ComponentContainer>
          <BottomMenu
            createButtonText="CREATE"
            createIsLoading={createNftApi.isLoading}
            createButtonOnClick={() => {
              if (form.validate()) {
                setShow(true);
                const formData = new FormData();
                formData.append("name", form.values.name);
                formData.append("quantity", form.values.quantity);
                formData.append("price", form.values.price);
                formData.append("description", form.values.description);
                formData.append(
                  "file",
                  form.values.file[0],
                  form.values.file[0].name
                );
                formData.append("partner", form.values.partner);
                formData.append("contract", form.values.contract);
                formData.append("minting_mode", form.values.minting_mode);
                formData.append("wallet_address", form.values.wallet_address);
                formData.append("private_key", form.values.private_key);
                createNftApi.executeQuery(formData);
              }
            }}
          ></BottomMenu>
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default CreateNft;
