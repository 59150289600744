import React from "react";

// Styles
import Style from "./ViewLoader.module.css";

const ViewLoader = () => {
  return (
    <div className={Style.loaderContainer}>
      <div className={Style.viewLoader}></div>
    </div>
  );
};

export default ViewLoader;
