import React from "react";

// Libraries
import { useTable } from "react-table";
import { Button } from "@hybris-software/ui-kit";
import styled from "styled-components";

// Styles
import Style from "./GeneralTable.module.css";

const CommonStyles = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #2e2d2d;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tbody tr {
      height: 50px;
      font-size: 13px;
      color: #c8c5c5;
    }

    th,
    td {
      position: relative;
      margin: 0;
      font-weight: 500;
      min-width: 200px;
      padding: 0.5rem;
      border-bottom: 1px solid #2e2d2d;
      border-right: 1px solid #2e2d2d;
      text-align: left;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Table = ({
  data,
  columns,
  height = "650px",
  Styles,
  isLoading,
  setUrl,
  title,
  subtitle,
  setSearch,
  searchPlaceholder = "Search...",
  sortedBy,
  setSortedBy,
  sortedType,
  setSortedType,
  emptyDataMessage = "No data available",
}) => {
  const ComputedStyles = Styles ? Styles : CommonStyles;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns,
      data: data.results,
    });

  function handleSort(column) {
    setSortedBy(column.field);
    if (sortedType === "asc") {
      setSortedType("desc");
    } else {
      setSortedType("asc");
    }
  }

  return (
    <ComputedStyles>
      <div className={Style.tableContainer}>
        {title && <h5>{title}</h5>}
        {subtitle && (
          <p
            style={{
              color: "white",
              fontSize: 15,
              marginBottom: 20,
              textDecoration: "underline",
            }}
          >
            {subtitle}
          </p>
        )}

        <div className={Style.searchBarContainer}>
          <input
            type="text"
            className={Style.inputSearch}
            placeholder={searchPlaceholder}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>

        <div className={Style.innerTable}>
          <div style={{ minHeight: height, position: "relative" }}>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div className={Style.clampedText}>
                          {column.render("Header")}
                          <span style={{ position: "absolute", right: 6 }}>
                            {column.field === sortedBy ? (
                              sortedType === "asc" ? (
                                <span
                                  onClick={() => {
                                    handleSort(column);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    style={{
                                      color: "white",
                                      marginRight: "-4px",
                                    }}
                                  >
                                    ↑
                                  </span>
                                  <span style={{ color: "gray" }}>↓</span>
                                </span>
                              ) : (
                                <span
                                  onClick={() => {
                                    handleSort(column);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    style={{
                                      color: "gray",
                                      marginRight: "-4px",
                                    }}
                                  >
                                    ↑
                                  </span>
                                  <span style={{ color: "white" }}>↓</span>
                                </span>
                              )
                            ) : (
                              <span
                                onClick={() => {
                                  handleSort(column);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span
                                  style={{ color: "gray", marginRight: "-4px" }}
                                >
                                  ↑
                                </span>
                                <span style={{ color: "gray" }}>↓</span>
                              </span>
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {isLoading ? (
                <div className={Style.tableLoader}></div>
              ) : data.results.length > 0 ? (
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                <div className={Style.clampedText}>
                                  {cell.render("Cell")}
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                    );
                  })}
                </tbody>
              ) : (
                <div className={Style.noResults}>{emptyDataMessage}</div>
              )}
            </table>
          </div>
        </div>

        <div className={Style.pagination}>
          <div className={Style.entries}>
            {"Showing " +
              data.from +
              " to " +
              data.to +
              " of " +
              data.count +
              " entries"}
          </div>
          <div className={Style.paginationButtons}>
            <Button
              disabled={data.links.previous === null || isLoading}
              buttonClassName={Style.buttonLeft}
              disabledClassName={Style.buttonDisabled}
              onClick={() => {
                if (data?.links.previous !== null) {
                  setUrl(data.links.previous.replace("http://", "https://"));
                }
              }}
            >
              Previous
            </Button>
            <div className={Style.paginationItem}>{data.page || "-"}</div>
            <Button
              disabled={data === null || data.links.next === null || isLoading}
              buttonClassName={Style.buttonRight}
              disabledClassName={Style.buttonDisabled}
              onClick={() => {
                if (data?.links.next !== null) {
                  setUrl(data.links.next.replace("http://", "https://"));
                }
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </ComputedStyles>
  );
};

export default Table;
