import React, { useState, useEffect } from "react";

// Components
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import InputFile from "../../Components/Commons/InputFile/InputFile";
import GeneralTable from "../../Components/Commons/GeneralTable/GeneralTable";

// Libraries
import { Row, Col, InputField, TextField } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";
import { useNavigate, useLocation } from "react-router-dom";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Config
import Config from "../../config";

// Styles
import Style from "./ViewNft.module.css";
import tableStyle from "../../Assets/css/Table.module.css";

const ViewNft = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Table
  const [tableData, setTableData] = useState([]);
  const [url, setUrl] = useState(null);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState(null);
  const [sortedBy, setSortedBy] = useState("progressive");
  const [sortedType, setSortedType] = useState("asc");

  const params = new URLSearchParams(window.location.search);
  const nftId = params.get("id");

  const columns = [
    {
      Header: "PROGRESSIVE",
      field: "progressive",
      accessor: "progressive",
    },
    {
      Header: "TRANSACTION ID",
      field: "transaction_id",
      accessor: "transaction_id",
    },
    {
      Header: "STATUS",
      field: "status",
      width: 500,
      minWidth: 500,
      accessor: (row) => {
        if (row.status === "MINTED") {
          return (
            <div className={tableStyle.badgeContainer}>
              <div className={tableStyle.minted}>Minted</div>
            </div>
          );
        } else if (row.status === "TRANSFERRED") {
          return (
            <div className={tableStyle.badgeContainer}>
              <div className={tableStyle.minting}>Transferred</div>
            </div>
          );
        }
      },
    },
  ];

  const getNftDetailsApi = useQuery({
    url: `nfts/${nftId}/`,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      form.getInputProps("name").setValue(response.data.name || "");
      form
        .getInputProps("quantity")
        .setValue(response.data.quantity.toString() || "");
      form
        .getInputProps("price")
        .setValue(response.data.price.toString() || "");
      form
        .getInputProps("description")
        .setValue(response.data.description || "");

      fetch(response.data.file)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          form.getInputProps("file").setValue([
            new File([blob], response.data.file_name, {
              type: "image/jpeg",
              path: response.data.file_name,
            }),
          ]);
        });

      form.getInputProps("partner").setValue(response.data.partner?.value || "");
      form
        .getInputProps("contract")
        .setValue(response.data.contract?.value || "");
      form
        .getInputProps("minting_mode")
        .setValue(response.data.minting_mode.value || "");
      form
        .getInputProps("wallet_address")
        .setValue(response.data.wallet_address || "");
      form.getInputProps("private_key").setValue(response.data.private_key);
    },
    onError: (error) => {
      navigate("/nfts");
    },
  });

  const tableApi = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      console.clear();
      setTableData(response.data);
    },
  });

  useEffect(() => {
    setUrl(
      `nfts/${nftId}/items/?status=${status}&search=${search}&field=${sortedBy}&sort=${sortedType}`
    );
    // eslint-disable-next-line
  }, [status, search, sortedBy, sortedType]);

  useEffect(() => {
    if (url) {
      tableApi.executeQuery();
    }
    //eslint-disable-next-line
  }, [url]);

  const form = useForm({
    inputs: {
      name: {
        required: true,
      },
      quantity: {
        required: true,
        formatter: (value) => {
          return value.replace(/[^0-9]/g, "");
        },
        validator: (value) => {
          if (value < 1) {
            return [false, "Supply must be greater than 0"];
          } else {
            return [true, null];
          }
        },
      },
      price: {
        required: true,
        formatter: (value) => {
          return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        },
        validator: (value) => {
          if (value < 1) {
            return [false, "Price must be greater than 0"];
          } else {
            return [true, null];
          }
        },
      },
      description: {
        required: true,
      },
      file: {
        required: true,
      },
      partner: {
        required: true,
      },
      contract: {
        required: true,
      },
      minting_mode: {
        required: true,
      },
      wallet_address: {
        required: true,
      },
      private_key: {},
    },
  });

  useEffect(() => {
    document.title = "Black Minter | View NFT";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        <ViewContainer
          title="Edit NFT"
          badgeStatus={getNftDetailsApi.response?.data.status}
          badgeCapitalizeFirstLetter={
            getNftDetailsApi.response?.data.status === "MINTING" ? false : true
          }
          badge={
            getNftDetailsApi.response?.data.status === "MINTING"
              ? getNftDetailsApi.response?.data.minted +
                "/" +
                getNftDetailsApi.response?.data.quantity +
                " " +
                "Minted"
              : getNftDetailsApi.response?.data.status
          }
        >
          <ComponentContainer title="NFT" minHeight="500px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Name</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={true}
                  style={{ backgroundColor: "#3c3c3c" }}
                  placeholder="Ex. Eagle NFT"
                  {...form.getInputProps("name")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Supply</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={true}
                  style={{ backgroundColor: "#3c3c3c" }}
                  placeholder="Ex. 10"
                  {...form.getInputProps("quantity")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Price (ETH)</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={true}
                  style={{ backgroundColor: "#3c3c3c" }}
                  placeholder="Ex. 1.2"
                  {...form.getInputProps("price")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Description</h5>
              </Col>
              <Col md={8} sm={12}>
                <TextField
                  readOnly={true}
                  style={{ backgroundColor: "#3c3c3c" }}
                  rows={10}
                  baseClassName={Style.textArea}
                  successClassName={Style.textArea}
                  errorClassName={Style.textAreaError}
                  placeholder="Provide a description of your NFT, this will be part of the public final NFT"
                  {...form.getInputProps("description")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>NFT Upload</h5>
              </Col>
              <Col md={8} sm={12}>
                {getNftDetailsApi.response && (
                  <InputFile
                    readOnly={true}
                    label="NFT Upload"
                    subLabel="Image, Video, Audio, or 3D Model"
                    name="image"
                    id="nft_image"
                    setFile={form.getInputProps("file").setValue}
                    file={form.getInputProps("file").value}
                    maxFileSize={Config.FILE_SIZE}
                    errorMessage={form.getInputProps("file").errorDetails}
                  />
                )}
              </Col>
            </Row>
          </ComponentContainer>
          <ComponentContainer margin="20px 0" title="Minting" minHeight="300px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Partner</h5>
              </Col>
              <Col md={8} sm={12}>
                {getNftDetailsApi.response && (
                  <InputField
                    readOnly={true}
                    style={{ backgroundColor: "#3c3c3c" }}
                    value={getNftDetailsApi.response.data?.partner?.label || ""}
                  />
                )}
              </Col>
              <Col md={4} sm={12}>
                <h5>Contract</h5>
              </Col>
              <Col md={8} sm={12}>
                {getNftDetailsApi.response && (
                  <InputField
                    readOnly={true}
                    style={{ backgroundColor: "#3c3c3c" }}
                    value={getNftDetailsApi.response.data?.contract?.label || ""}
                  />
                )}
              </Col>
              <Col md={4} sm={12}>
                <h5>Minting Mode</h5>
              </Col>
              <Col md={8} sm={12}>
                {getNftDetailsApi.response && (
                  <InputField
                    readOnly={true}
                    style={{ backgroundColor: "#3c3c3c" }}
                    {...form.getInputProps("minting_mode")}
                    value={getNftDetailsApi.response.data.minting_mode.label}
                  />
                )}
              </Col>
              <Col md={4} sm={12}>
                <h5>Wallet</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={true}
                  style={{ backgroundColor: "#3c3c3c" }}
                  placeholder="Ex. 1.2"
                  {...form.getInputProps("wallet_address")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Private Key</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  readOnly={true}
                  style={{ backgroundColor: "#3c3c3c" }}
                  placeholder="Ex. 1.2"
                  {...form.getInputProps("private_key")}
                />
              </Col>
            </Row>
          </ComponentContainer>
          {getNftDetailsApi.response?.data.status !== "DRAFT" && (
            <ComponentContainer
              margin="20px 0"
              title="Items"
              minHeight="300px"
              filters={[
                {
                  name: "All",
                  default: true,
                  onClick: () => {
                    setStatus("ALL");
                  },
                },
                {
                  name: "Transferred",
                  onClick: () => {
                    setStatus("TRANSFERRED");
                  },
                },
                {
                  name: "Minted",
                  onClick: () => {
                    setStatus("MINTED");
                  },
                },
              ]}
            >
              {tableData && (
                <GeneralTable
                  data={tableData}
                  columns={columns}
                  setUrl={setUrl}
                  setSearch={setSearch}
                  isLoading={tableApi.isLoading}
                  sortedBy={sortedBy}
                  setSortedBy={setSortedBy}
                  sortedType={sortedType}
                  setSortedType={setSortedType}
                />
              )}
            </ComponentContainer>
          )}
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default ViewNft;
