import React from "react";

// Components
import Style from "./ViewContainer.module.css";

// Images
import CopyRight from "./assets/logo_copy.svg";

// Libraries
import { Button } from "@hybris-software/ui-kit";

// Utils
import capitalizeFirstLetter from "../../../Utils/capitalizeFirstLetter";

const ViewContainer = ({
  title,
  badge,
  badgeStatus,
  buttonText,
  buttonIsLoading,
  buttonOnClick,
  badgeTextColor,
  badgeBackgroundColor,
  badgeCapitalizeFirstLetter,
  children,
}) => {
  return (
    <div className={Style.mainContainer}>
      <div>
        <div className={Style.subHeaderPage}>
          <div className={Style.viewTitle}>
            {title}
            {badge && (
              <span
                className={
                  badgeStatus === "ACTIVE"
                    ? Style.active
                    : badgeStatus === "DISABLED"
                    ? Style.disabled
                    : badgeStatus === "CREATED"
                    ? Style.created
                    : badgeStatus === "DEPLOYED"
                    ? Style.deployed
                    : badgeStatus === "DRAFT"
                    ? Style.draft
                    : badgeStatus === "MINTING"
                    ? Style.minting
                    : badgeStatus === "MINTED"
                    ? Style.minted
                    : badgeStatus === "TRANSFERRED"
                    ? Style.transferred
                    : Style.badge
                }
                style={{
                  color: badgeTextColor,
                  backgroundColor: badgeBackgroundColor,
                }}
              >
                {badgeCapitalizeFirstLetter !== false
                  ? capitalizeFirstLetter(badge)
                  : badge}
              </span>
            )}
          </div>
          {buttonText && (
            <Button
              isLoading={buttonIsLoading}
              onClick={() => {
                buttonOnClick();
              }}
            >
              {buttonText}
            </Button>
          )}
        </div>
        <div className={Style.viewBody}>{children}</div>
      </div>
      <div className={Style.copyContainer}>
        Copyright @ 2022
        <img
          src={CopyRight}
          alt="logo-copy"
          width={"25px"}
          height={"25px"}
          style={{ marginLeft: 3, marginRight: 3 }}
        />
        BLACK NACHOS srl
      </div>
    </div>
  );
};

export default ViewContainer;
