import React from "react";

// Styles
import Style from "./ComponentContainer.module.css";

const ComponentContainer = ({
  minHeight = "auto",
  margin = "0px",
  title,
  filters = [],
  children,
}) => {
  function getRandomString(len) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < len; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  const dynamicId = getRandomString(10);

  return (
    <div
      className={Style.mainFormContainer}
      style={{
        minHeight: minHeight,
        margin: margin,
      }}
    >
      <div className={Style.formHeader}>
        <span>{title}</span>
        <span className={Style.filters} id={dynamicId}>
          {filters.map((filter, index) => {
            return (
              <span
                key={index}
                className={
                  Style.filter +
                  (filter.default === true ? " " + Style.filterActive : "")
                }
                onClick={(e) => {
                  const toggleContainer = document.getElementById(dynamicId);
                  const toggleElement = toggleContainer.querySelector(
                    "." + Style.filterActive
                  );
                  toggleElement.classList.remove(Style.filterActive);
                  e.target.classList.toggle(Style.filterActive);
                  filter.onClick();
                }}
              >
                {filter.name}
              </span>
            );
          })}
        </span>
      </div>
      <div className={Style.formDivider}></div>
      <div className={Style.formBody}>{children}</div>
    </div>
  );
};

export default ComponentContainer;
