import axios from "axios";
import parseJwt from "./parseJwt";

function generateJwtApiClient({
    baseUrl = "",
    authorizationHeader = "Authorization",
    authorizationPrefix = "Bearer ",
    accessTokenLocalStorageKey = "accessToken",
    refreshTokenLocalStorageKey = "refreshToken",
    refreshTokenFunction = undefined,
}) {
    const apiClient = axios.create({
        baseURL: baseUrl,
        timeout: 10000,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });

    apiClient.interceptors.request.use(
        async function (config) {
            config.headers = config.headers || {};

            let accessToken =
                localStorage.getItem(accessTokenLocalStorageKey)
            let refreshToken =
                localStorage.getItem(refreshTokenLocalStorageKey)

            if (accessToken) {
                // Check if the access token is expired
                const decoded = parseJwt(accessToken);
                if (
                    decoded.exp &&
                    decoded.exp < Date.now() / 1000 &&
                    refreshToken &&
                    refreshTokenFunction
                ) {
                    // If the access token is expired, try to refresh it
                    const decodedRefresh = parseJwt(refreshToken);
                    if (decodedRefresh.exp && decodedRefresh.exp > Date.now() / 1000) {
                        try {
                            const [newAccessToken, newRefreshToken] =
                                await refreshTokenFunction({ accessToken, refreshToken });
                            localStorage.setItem(
                                accessTokenLocalStorageKey,
                                newAccessToken
                            );
                            localStorage.setItem(
                                refreshTokenLocalStorageKey,
                                newRefreshToken
                            );
                            accessToken = newAccessToken;
                            refreshToken = newRefreshToken;
                        } catch (error) {
                        }
                    } else {
                        localStorage.removeItem(accessTokenLocalStorageKey);
                        localStorage.removeItem(refreshTokenLocalStorageKey);
                    }
                }
                config.headers[authorizationHeader] = `${authorizationPrefix}${accessToken}`;
            } else {
                localStorage.removeItem(accessTokenLocalStorageKey);
                localStorage.removeItem(refreshTokenLocalStorageKey);
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    apiClient.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    return apiClient;
}

export default generateJwtApiClient;
