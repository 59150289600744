import React, { useState, useMemo, useEffect } from "react";

// Components
import NoPreviewAvailable from "../NoPreviewAvailable/NoPreviewAvailable";
import GeneralPopup from "../GeneralPopup/GeneralPopup";

// Libraries
import { Row, Col } from "@hybris-software/ui-kit";
import { useDropzone } from "react-dropzone";

// Icons
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineCloudUpload } from "react-icons/ai";

// Utils
import detectIfIsImageFromFileName from "../../../Utils/detectIfIsImageFromFileName";

// Styles
import Style from "./InputFile.module.css";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#2e2d2d",
  borderStyle: "dashed",
  backgroundColor: "#1b1a1a",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#8700e0",
};

const acceptStyle = {
  borderColor: "#00ff6271",
};

const rejectStyle = {
  borderColor: "#ff000071",
};

const InputFile = ({
  maxFileSize,
  setFile,
  errorPopup,
  file,
  onChange,
  readOnly = false,
  errorMessage,
}) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file) {
      if (file[0].loaded) {
        setPreview(file[0].file);
      } else {
        setPreview(URL.createObjectURL(file[0]));
      }
    }
  }, [file]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [], "video/*": [], "audio/*": [] },
      multiple: false,
      maxFiles: 1,
      onDropAccepted: (file) => {
        if (onChange) {
          onChange();
        }
        if (maxFileSize !== undefined) {
          if (file[0].size < maxFileSize) {
            setPreview(URL.createObjectURL(file[0]));
            setFile(file);
          } else {
            errorPopup(
              <GeneralPopup
                title="Error"
                text={`File size is too big. Please try again. The maximum file size is ${
                  maxFileSize / 1024 / 1024
                } MB.`}
                maxWidth="350px"
                icon={false}
                buttonText={"Continue"}
                buttonOnClick={() => {
                  errorPopup(false);
                }}
              />
            );
          }
        } else {
          setPreview(URL.createObjectURL(file[0]));
          setFile(file);
        }
      },
      onDropRejected: () => {},
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Row className={Style.inputFormContainer}>
      <Col xs={12} sm={12} md={12}>
        {!file ? (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div>
              <AiOutlineCloudUpload className={Style.iconUpload} />
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "end" }}>
            {detectIfIsImageFromFileName(file[0].name) === false ? (
              <NoPreviewAvailable />
            ) : (
              <a href={preview} target="_blank" rel="noopener noreferrer">
                <div
                  className={Style.previewContainer}
                  style={{ backgroundImage: "url(" + preview + ")" }}
                ></div>
              </a>
            )}

            <div className={Style.fileInfoContainer}>
              {!readOnly && (
                <div
                  className={Style.deleteButton}
                  onClick={() => {
                    setFile(null);
                    setPreview(null);
                  }}
                >
                  <RiDeleteBin6Line />
                </div>
              )}
              <div className={Style.fileName}>{file[0].name}</div>
              <span className={Style.fileInfoSize}>
                {(file[0].size).toFixed(2) + " MB"}
              </span>
            </div>
          </div>
        )}
        {errorMessage && <p className={Style.errorMessage}>{errorMessage}</p>}
      </Col>
    </Row>
  );
};
export default InputFile;
