import React from "react";
import Style from "./InputSwitch.module.css";

const InputSwitch = ({ label, subLabel, onChange, value }) => {
  return (
    <div className={Style.switchContainer}>
      <div className={Style.labelsContainer}>
        <div className={Style.inputLabel}>{label}</div>
        {subLabel && <div className={Style.inputSubLabel}>{subLabel}</div>}
      </div>
      <input
        className={Style.appleSwitch}
        type="checkbox"
        onChange={() => {
          onChange();
        }}
        checked={value}
      />
    </div>
  );
};

export default InputSwitch;
