import React from "react";
import Style from "./Loader.module.css";
import { TailSpin } from "react-loading-icons";

const Loader = (props) => {
  return (
    <div className={Style.loaderContainer} style={{ height: props.height }}>
      <TailSpin />
    </div>
  );
};

export default Loader;
