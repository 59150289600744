import React, { useEffect, useState } from "react";

// Libraries
import { useNavigate } from "react-router-dom";
import { InputField, Button } from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query/";
import { PermissionRoute } from "@hybris-software/use-auth";
import ViewLoader from "../../../Components/Commons/ViewLoader/ViewLoader";

// Icons
import { RiLockPasswordFill } from "react-icons/ri";

// Images
import Logo from "./assets/logo-extended.svg";

// Styles
import Style from "./ResetPassword.module.css";

const Login = () => {
  const navigate = useNavigate();
  const [messageSuccess, setMessageSuccess] = useState(false);

  useEffect(() => {
    document.title = "Black Minter | Reset Password";
  }, []);

  // get params from url not using useQuery
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const uid = params.get("uid");

  const form = useForm({
    inputs: {
      password: {
        nature: "password",
        apiName: "new_password1",
      },
      confirmPassword: {
        nature: "confirmPassword",
        apiName: "new_password2",
      },
      uid: {
        value: uid,
      },
      token: {
        value: token,
      },
    },
  });

  const resetPassword = useQuery({
    url: "auth/password/reset/confirm/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setMessageSuccess(true);
    },
    onError: (error) => {
      setMessageSuccess(true);
    },
  });

  return (
    <PermissionRoute
      loader={<ViewLoader />}
      minimumLoadingTime={2000}
      forLoggedUser={false}
      unAuthorizedAction={() => {
        navigate("/");
      }}
      permissionController={() => {
        return true;
      }}
    >
      <div className={Style.mainContainer}>
        {/* Logo */}
        <div className={Style.logoContainer}>
          <img src={Logo} alt="logo" width={150}></img>
        </div>

        {/* Reset Password Form */}
        <div className={Style.loginContainer}>
          <div className={Style.loginHeader}>
            <h3>Reset Password</h3>

            {!messageSuccess ? (
              <p>Enter the email you used to register your account</p>
            ) : (
              <div>
                <p>Password Reset correctly</p>
                <Button
                  style={{ display: "flex" }}
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                >
                  Login
                </Button>
              </div>
            )}
          </div>
          {!messageSuccess && (
            <>
              <form>
                <InputField
                  baseClassName={Style.baseClassInput}
                  errorMessageClassName={Style.errorMessageClassName}
                  errorClassName={Style.errorClassName}
                  successClassName={Style.successClassName}
                  errorIconVisibility={false}
                  icon={
                    <div className={Style.inputIcon}>
                      <RiLockPasswordFill />
                    </div>
                  }
                  type="password"
                  placeholder="New Password"
                  {...form.getInputProps("password")}
                />
                <InputField
                  baseClassName={Style.baseClassInput}
                  errorMessageClassName={Style.errorMessageClassName}
                  errorClassName={Style.errorClassName}
                  successClassName={Style.successClassName}
                  errorIconVisibility={false}
                  icon={
                    <div className={Style.inputIcon}>
                      <RiLockPasswordFill />
                    </div>
                  }
                  placeholder="Confirm Password"
                  type="password"
                  {...form.getInputProps("confirmPassword")}
                />
              </form>

              <Button
                style={{ display: "flex" }}
                disabled={resetPassword.isLoading || !form.isValid()}
                onClick={() => {
                  resetPassword.executeQuery(form.getApiBody());
                }}
                isLoading={resetPassword.isLoading}
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </div>
    </PermissionRoute>
  );
};

export default Login;
