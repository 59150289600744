import React, { useState } from "react";

// Components
import SideBar from "../../Components/Commons/SideBar/SideBar";
import NavBar from "../../Components/Commons/NavBar/NavBar";
import LayoutLoader from "../../Components/Commons/LayoutLoader/LayoutLoader";

// Libraries
import { Outlet, useNavigate } from "react-router-dom";
import { PermissionRoute } from "@hybris-software/use-auth";

// Styles
import Style from "./MainLayout.module.css";

const MainLayout = () => {
  const [compactMenu, setCompactMenu] = useState("open");
  const [role, setRole] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [initials, setInitials] = useState(null);
  const [userId, setUserId] = useState(null);
  const [profilePopUp, setProfilePopUp] = useState("none");
  const [notificationPopUp, setNotificationPopUp] = useState("none");
  const [searchPopup, setSearchPopup] = useState("none");

  const navigate = useNavigate();

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<LayoutLoader />}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      apiLoading={false}
      permissionController={(response) => {
        setRole(response.data.role);
        setFullName(response.data.full_name);
        setEmail(response.data.email);
        setInitials(response.data.initials);
        setUserId(response.data.id);
        return true;
      }}
    >
      <div
        className={Style.fullPageContainer}
        onClick={(e) => {
          let clickOnIcon;
          if (
            e.target.tagName === "path" &&
            e.target.parentNode.id === "notificationPopup"
          ) {
            clickOnIcon = true;
          } else {
            clickOnIcon = false;
          }

          if (e.target.id !== "notificationPopup" && clickOnIcon === false) {
            if (profilePopUp === "block") {
              setProfilePopUp("none");
            }
            if (notificationPopUp === "block") {
              setNotificationPopUp("none");
            }
            if (searchPopup === "block" && e.target.id !== "searchBarNav") {
              setSearchPopup("none");
            }
          }
        }}
      >
        <>
          <NavBar
            compactMenu={compactMenu}
            setCompactMenu={setCompactMenu}
            role={role}
            fullName={fullName}
            email={email}
            initials={initials}
            userId={userId}
            setProfilePopUp={setProfilePopUp}
            setNotificationPopUp={setNotificationPopUp}
            profilePopUp={profilePopUp}
            notificationPopUp={notificationPopUp}
            searchPopUp={searchPopup}
            setSearchPopUp={setSearchPopup}
          />
          <main className={Style.mainContainer}>
            <div className={Style.mainContent}>
              <SideBar
                role={role}
                compactMenu={compactMenu}
                setCompactMenu={setCompactMenu}
              />
              <div
                className={
                  compactMenu === "open"
                    ? Style.pageContainer
                    : compactMenu === "compact"
                    ? Style.pageContainerCompact
                    : Style.pageContainer
                }
                onClick={() => {
                  const width = document.documentElement.clientWidth;
                  if (width < 991) {
                    if (compactMenu === "compact") {
                      setCompactMenu("open");
                    }
                  }
                }}
              >
                <Outlet />
              </div>
            </div>
          </main>
        </>
      </div>
    </PermissionRoute>
  );
};

export default MainLayout;
