import React, { useState, useEffect } from "react";

// Componentns
import ViewLoader from "../../Components/Commons/ViewLoader/ViewLoader";
import ComponentContainer from "../../Components/Commons/ComponentContainer/ComponentContainer";
import ViewContainer from "../../Components/Commons/ViewContainer/ViewContainer";
import BottomMenu from "../../Components/Commons/BottomMenu/BottomMenu";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import { InputField, Row, Col } from "@hybris-software/ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Utils
import dispatcher from "../../Utils/generalDispatcher";

// Styles
import Style from "./Wallet.module.css";

const Wallet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(null);

  const getWalletDetailsApi = useQuery({
    url: "wallet/",
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      form
        .getInputProps("wallet_address")
        .setValue(response.data.wallet_address || "");
      form
        .getInputProps("private_key")
        .setValue(response.data.private_key || "");
    },
  });

  const walletApi = useQuery({
    url: "wallet/",
    method: "PUT",
    executeImmediately: false,
    onSuccess: () => {
      setPopup(
        <GeneralPopup
          showIcon={false}
          setPopup={setPopup}
          title="Success!"
          text="Wallet Updated Successfully"
          buttonOnClick={() => {
            setPopup(null);
          }}
          buttonText="Continue"
          width={400}
        />
      );
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        form.fetchQueryErrors(error.response.data);
      } else {
        setPopup(
          <GeneralPopup
            showIcon={true}
            setPopup={setPopup}
            title={`Error`}
            text={
              <span>
                {error.message && error.message} <br />
                {error.response.data.error && error.response.data.error}
              </span>
            }
            buttonOnClick={() => {
              setPopup(null);
            }}
            buttonText="Continue"
            width={400}
          />
        );
      }
    },
  });

  const form = useForm({
    inputs: {
      wallet_address: {
        required: true,
      },
      private_key: {
        required: true,
      },
    },
  });

  useEffect(() => {
    document.title = "Black Minter | Wallet";
  }, []);

  return (
    <PermissionRoute
      minimumLoadingTime={1000}
      forLoggedUser={true}
      loader={<ViewLoader />}
      apiLoading={getWalletDetailsApi.isLoading}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={(response) => {
        dispatcher(response.data, location.pathname, navigate);
        return true;
      }}
    >
      <main>
        {popup && popup}
        <ViewContainer title="Platform Wallet">
          <ComponentContainer title="Wallet" minHeight="500px">
            <Row className={Style.formRow}>
              <Col md={4} sm={12}>
                <h5>Wallet Address</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="Ex. 1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa"
                  {...form.getInputProps("wallet_address")}
                />
              </Col>
              <Col md={4} sm={12}>
                <h5>Private Key</h5>
              </Col>
              <Col md={8} sm={12}>
                <InputField
                  placeholder="E9873D79C6D87DC0FB213303DA61F20BD67FC233AA33262"
                  {...form.getInputProps("private_key")}
                />
              </Col>
            </Row>
          </ComponentContainer>
          <BottomMenu
            createButtonText="SAVE"
            createIsLoading={walletApi.isLoading}
            createButtonOnClick={() => {
              if (form.validate()) {
                walletApi.executeQuery(form.getApiBody());
              }
            }}
          ></BottomMenu>
        </ViewContainer>
      </main>
    </PermissionRoute>
  );
};

export default Wallet;
